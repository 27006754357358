import { useEffect, useState } from "react";
import "../../../Monitors/Components/replyModal/replyModal.scss";
import { isEmptyObj } from "utils/helpers";
import Reply from "./reply";

const ReplyModalChat = (props) => {
  const {
    replyData,
    showSuccessModal,
    selectedCard,
    chatData,
    setChatData,
    setStatusLogs,
    setSelectedCardIteractionID,
    setOpenDetailsSideBar,
    setOpenDetailsSideBarReplay,
    setOpenSlaSideBar,
    text,
    setText,
    selectedValue,
    setSelectedValue,
    setOpenSidebar,
    setCheckAiSuggestions,
    translationActive,
    setTranslationActive,
    translationSource,
    setTranslationSource,
    translationTarget,
    setTranslationTarget,
    stats,
    setStats,
    languagesArraySource,
    languagesArrayTarget,
    translateStatus,
    setTranslateStatus,
    headerData,
    setDeletedMesssagesIds,
    openSidebar,
    openDetailsSideBarReplay,
    isSidebarsOpened,
    showAllIcon,
    aiAgentList,
    setAiAgentList,
    setRefresh,
    setOpenSnackBarAiAgent,
    setSnackBarDataAiAgent,
    setInjectedMessage,
    aiAgentId,
    replyBoxInputTextRef,
    reduxSlaKeys,
    itemSelected,
  } = props;

  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [limitRateDate, setLimitRateDate] = useState("");
  const [snackBarSeverity, setSnackbarSeverity] = useState("");
  const [snackBarMessage, setSnackbarMessage] = useState("");
  const [snackBarTitle, setSnackbarTitle] = useState("");
  const [selectedFile, setSelectedFile] = useState([]);
  const [base64Code, setBase64Code] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [mediaLoader, setMediaLoader] = useState(false);
  const [mediaFile, setMediaFile] = useState([]);
  const [newMsg, setNewMsg] = useState({});
  const [originalReplay, setOriginalReply] = useState("");

  useEffect(() => {
    if (!isEmptyObj(newMsg)) {
      newMsg.original_replay = translateStatus ? originalReplay : null;
      let checkIsExist = chatData.filter(
        (item) => item?.message_id == newMsg?.message_id,
      );

      if (newMsg?.system_replies?.replier_info && !newMsg?.is_temporary) {
        setStatusLogs([
          {
            created_at: newMsg?.system_replies?.created_at,
            status: newMsg?.system_replies?.status,
            user_email: newMsg?.system_replies?.replier_info?.email,
          },
        ]);
      }
      if (!checkIsExist?.length) {
        setChatData([...chatData, newMsg]);
      } else {
        let data = [...chatData];
        chatData.map((item, i) => {
          if (item?.message_id == newMsg?.message_id) {
            data[i] = { ...newMsg };
          }
        });
        setChatData([...data]);
      }

      setNewMsg({});
      setBtnLoading(false);
    }
  }, [newMsg, chatData]);

  return (
    <Reply
      replyType="dm"
      setSelectedCardIteractionID={setSelectedCardIteractionID}
      replyData={replyData}
      showSuccessModal={showSuccessModal}
      selectedCard={selectedCard}
      showSnackBar={showSnackBar}
      setShowSnackBar={setShowSnackBar}
      limitRateDate={limitRateDate}
      setLimitRateDate={setLimitRateDate}
      snackBarSeverity={snackBarSeverity}
      setSnackbarSeverity={setSnackbarSeverity}
      snackBarMessage={snackBarMessage}
      setSnackbarMessage={setSnackbarMessage}
      snackBarTitle={snackBarTitle}
      setSnackbarTitle={setSnackbarTitle}
      selectedFile={selectedFile}
      setSelectedFile={setSelectedFile}
      base64Code={base64Code}
      setBase64Code={setBase64Code}
      attachments={attachments}
      setAttachments={setAttachments}
      text={text}
      setText={setText}
      mediaFile={mediaFile}
      setMediaFile={setMediaFile}
      mediaLoader={mediaLoader}
      setMediaLoader={setMediaLoader}
      btnLoading={btnLoading}
      setBtnLoading={setBtnLoading}
      selectedValue={selectedValue}
      setSelectedValue={setSelectedValue}
      loading={loading}
      setLoading={setLoading}
      setNewMsg={setNewMsg}
      setOpenDetailsSideBar={setOpenDetailsSideBar}
      setOpenDetailsSideBarReplay={setOpenDetailsSideBarReplay}
      setOpenSlaSideBar={setOpenSlaSideBar}
      setOpenSidebar={setOpenSidebar}
      setCheckAiSuggestions={setCheckAiSuggestions}
      translationActive={translationActive}
      setTranslationActive={setTranslationActive}
      translationSource={translationSource}
      setTranslationSource={setTranslationSource}
      translationTarget={translationTarget}
      setTranslationTarget={setTranslationTarget}
      stats={stats}
      setStats={setStats}
      languagesArraySource={languagesArraySource}
      languagesArrayTarget={languagesArrayTarget}
      translateStatus={translateStatus}
      setTranslateStatus={setTranslateStatus}
      headerData={headerData}
      chatData={chatData}
      setDeletedMesssagesIds={setDeletedMesssagesIds}
      setOriginalReply={setOriginalReply}
      openSidebar={openSidebar}
      openDetailsSideBarReplay={openDetailsSideBarReplay}
      isSidebarsOpened={isSidebarsOpened}
      showAllIcon={showAllIcon}
      aiAgentList={aiAgentList}
      setAiAgentList={setAiAgentList}
      setRefresh={setRefresh}
      setOpenSnackBarAiAgent={setOpenSnackBarAiAgent}
      setSnackBarDataAiAgent={setSnackBarDataAiAgent}
      setInjectedMessage={setInjectedMessage}
      aiAgentId={aiAgentId}
      replyBoxInputTextRef={replyBoxInputTextRef}
      reduxSlaKeys={reduxSlaKeys}
      itemSelected={itemSelected}
    />
  );
};

export default ReplyModalChat;
