import { useState, useEffect } from "react";
import { Menu, MenuItem, Button, Box } from "@mui/material";
import { PlayArrow, Delete, Pause, Sync, Edit } from "@mui/icons-material";
import SnackBar from "components/snackBar.js";
//Locals
import { useIntl } from "react-intl";
import {
  facebookAuth,
  gmailAuth,
  twitterAuth,
  gmbAuth,
  linkedInAuth,
  tiktokAuth,
} from "./manageChannels.hooks";
import "./manageChannels.scss";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import DmConfigurationEdit from "./addChannel/dmConfigurationEdit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faHeadset } from "@fortawesome/free-solid-svg-icons";
import { DeleteChannelModal } from "./components/DeleteChannelModal";
import { PauseOrResumeChannelModal } from "./components/PauseOrResumeChannelModal";
import { EditCustomerCareModal } from "./components/EditCustomerCareModal";
import CustomizedTooltip from "components/customizedTooltip";

const OptionsDropDown = (props) => {
  const intl = useIntl();

  const {
    channelName,
    monitorOwner,
    accountError,
    accountValidError,
    setOpenGenesysModal,
    disableReauth,
    tiktokDataSourceId,
    setFailSnackBarData,
    setOpenSnackBarAllSource,
    customerCare,
    ignoreTweets,
    setIsSuccessEditSnackBarOpen,
    setIsErrorEditSnackBarOpen,
  } = props;

  const excludedDataSources = channelName?.toLowerCase() === "intercom";

  const [stats, setStats] = useState({
    anchorEl: null,
    openSnackBar: false,
    snackBarAction: "",
    snackBarSeverity: "success",
  });

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isPauseOrResumeModalOpen, setIsPauseOrResumeModalOpen] =
    useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const open = Boolean(stats.anchorEl);

  const [isCustomerCareModalOpen, setIsCustomerCareModalOpen] = useState(false);

  const channelId = props?.channelId;

  window.addEventListener("scroll", (event) => {
    if (stats.anchorEl) {
      handleClose();
    }
  });

  const handleClick = (event) => {
    setStats({
      ...stats,
      anchorEl: event.currentTarget,
    });
  };

  const handleClose = () => {
    setStats({ ...stats, anchorEl: null });
  };

  const getChannelActionsSnackBarMessage = (action, severity) => {
    const messages = {
      delete: {
        success: CheckValueLocale("channel_deleted", "", {}, intl),
        warning: CheckValueLocale("failed_error", "", {}, intl),
      },
      pauseOrResume: {
        success: CheckValueLocale("channel_updated", "", {}, intl),
        warning: CheckValueLocale("failed_error", "", {}, intl),
      },
      auth_success: {
        success: CheckValueLocale("authenticate_success_msg", "", {}, intl),
      },
      auth_error: {
        warning: CheckValueLocale("authenticate_error_msg", "", {}, intl),
      },
    };
    return messages[action]?.[severity] || "";
  };

  const showSnackBar = (action, severity) => {
    const snackBarMsg = getChannelActionsSnackBarMessage(action, severity);
    setStats({
      ...stats,
      openSnackBar: true,
      snackBarAction: action,
      snackBarSeverity: severity,
      snackBarMsg,
    });
    window.scrollTo(0, 0);
  };

  const handleOpenEditModal = () => {
    setIsEditModalOpen(true);
    setStats({
      ...stats,
      anchorEl: false,
    });
  };

  const handleCloseSnackBar = () => {
    setStats({ ...stats, openSnackBar: false });
  };

  const handleReauth = (channelName) => {
    const redirectUrl = window.location.href;

    switch (channelName) {
      case "TWITTER":
        twitterAuth(props.companyApps, redirectUrl, channelName);
        break;
      case "FACEBOOK":
        facebookAuth(props.companyApps, redirectUrl, channelName);
        break;
      case "WHATSAPP":
        facebookAuth(
          props.companyApps,
          redirectUrl,
          channelName,
          undefined,
          undefined,
          "whatsapp",
        );
        break;
      case "GMAIL":
        gmailAuth(props.companyApps, redirectUrl, channelName);
        break;
      case "INTERCOM":
        // handleInterComDialog();
        break;
      case "INSTAGRAM":
        facebookAuth(props.companyApps, redirectUrl, channelName);
        break;
      case "googlemybusiness":
        gmbAuth(props.companyApps, redirectUrl, channelName);
        break;
      case "LINKEDIN":
        linkedInAuth(props.companyApps, redirectUrl, channelName);
        break;
      case "GENESYS":
        setOpenGenesysModal(true);
        break;
      case "TIKTOK":
        tiktokAuth(
          redirectUrl,
          tiktokDataSourceId,
          setFailSnackBarData,
          setOpenSnackBarAllSource,
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (window.location.href.includes("/manage-channels/list?success=1")) {
      setStats({ ...stats, openSnackBar: true });
      showSnackBar("auth_success", "success");
      window.history.pushState({}, "", window.location.href.split("?")[0]);
    } else if (
      window.location.href.includes("/manage-channels/list?success=0") ||
      window.location.href.includes("/manage-channels/list?auth=failed")
    ) {
      setStats({ ...stats, openSnackBar: true });
      showSnackBar("auth_error", "warning");
      window.history.pushState({}, "", window.location.href.split("?")[0]);
    }
  }, [window.location.href]);

  const userCanAuthenticate =
    handlelUserRoles("GENERAL", "CREATE_COMPANY_AUTH") ||
    handlelUserRoles("GENERAL", "CREATE_INTEGRATION");

  const userCanResumeOrPause =
    handlelUserRoles("CXM", "EDIT_CHANNEL") ||
    handlelUserRoles("CXM", "EDIT_OTHERS_CHANNEL");

  const userCanDelete =
    handlelUserRoles("CXM", "DESTROY_CHANNEL") ||
    handlelUserRoles("CXM", "DESTROY_OTHERS_CHANNEL");

  const isResumeOrPauseChannelDisable =
    accountError?.length > 0 ||
    accountValidError?.length > 0 ||
    props.status === "unapproved"
      ? true
      : false;

  const customerCareMenuItemVisible =
    !props?.dm_channel &&
    (props?.channelName == "FACEBOOK" ||
      props?.channelName == "TWITTER" ||
      props?.channelName == "INSTAGRAM");

  return (
    <div>
      <CustomizedTooltip
        id="menu-item-list-tooltip"
        title={
          <Box className="item-title-tooltip">
            {CheckValueLocale("items_ellipsis", "", {}, intl)}
          </Box>
        }
        arrow
        placement="bottom"
      >
        <Button
          variant="text"
          aria-label="more"
          className={
            "menu-items menu-item-container " + (open ? "menu-item-active" : "")
          }
          id="channel-analytics-manage-channels-long-button"
          aria-controls="long-menu"
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          onClose={handleClose}
        >
          <FontAwesomeIcon className="ellipsis-icon" icon={faEllipsisH} />
        </Button>
      </CustomizedTooltip>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={stats.anchorEl}
        open={open}
        onClose={handleClose}
        className="manage-channels-dropdown manage-menu"
      >
        {userCanAuthenticate && (
          <MenuItem
            onClick={() => handleReauth(props.channelName)}
            disableRipple
            disabled={
              !monitorOwner ||
              excludedDataSources ||
              (channelName?.toLowerCase() === "whatsapp" && disableReauth)
                ? true
                : false
            }
            id={`managed-channels-re-authenticate-${
              props?.channelId ?? "channel-id"
            }`}
          >
            <Sync />
            {CheckValueLocale("re_authenticate", "", {}, intl)}
          </MenuItem>
        )}
        {customerCareMenuItemVisible ? (
          <MenuItem onClick={() => setIsCustomerCareModalOpen(true)}>
            <Edit />
            {CheckValueLocale("edit_configuration", "", {}, intl)}
          </MenuItem>
        ) : null}

        {props?.dm_channel === 1 ? (
          <MenuItem
            disableRipple
            onClick={() => handleOpenEditModal()}
            id={`managed-channels-edit-configuration${
              props?.channelId ?? "channel-id"
            }`}
          >
            <Edit />
            {CheckValueLocale("edit_configuration", "", {}, intl)}
          </MenuItem>
        ) : null}

        {userCanResumeOrPause ? (
          <MenuItem
            onClick={() => setIsPauseOrResumeModalOpen(true)}
            disableRipple
            disabled={isResumeOrPauseChannelDisable}
            id={`managed-channels-resume-or-pause-${
              props?.channelId ?? "channel-id"
            }`}
          >
            {props.status === "paused" ? <PlayArrow /> : <Pause />}
            {props.status === "paused"
              ? CheckValueLocale("resume", "", {}, intl)
              : CheckValueLocale("pause", "", {}, intl)}
          </MenuItem>
        ) : null}
        {userCanDelete ? (
          <MenuItem
            onClick={() => setIsDeleteModalOpen(true)}
            disableRipple
            id={`managed-channels-delete-${props?.channelId ?? "channel-id"}`}
          >
            <Delete />
            {CheckValueLocale("delete", "", {}, intl)}
          </MenuItem>
        ) : null}
      </Menu>

      {isEditModalOpen ? (
        <DmConfigurationEdit
          isEditModalOpen={isEditModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
          monitorId={props.channelId}
          dm_Configuration_Id={props.dm_Configuration_Id}
          setIsSuccessEditSnackBarOpen={setIsSuccessEditSnackBarOpen}
          setIsErrorEditSnackBarOpen={setIsErrorEditSnackBarOpen}
        />
      ) : null}

      <PauseOrResumeChannelModal
        accountName={props.accountName}
        channelId={props.channelId}
        open={isPauseOrResumeModalOpen}
        close={() => setIsPauseOrResumeModalOpen(false)}
        showSnackBar={showSnackBar}
        status={props.status}
      />

      <EditCustomerCareModal
        channelId={channelId}
        channelDataSource={props?.channelName}
        close={() => setIsCustomerCareModalOpen(false)}
        open={isCustomerCareModalOpen}
        customerCare={customerCare}
        ignoreTweets={ignoreTweets}
      />

      <DeleteChannelModal
        accountName={props.accountName}
        channelId={props.channelId}
        close={() => setIsDeleteModalOpen(false)}
        open={isDeleteModalOpen}
        showSnackBar={showSnackBar}
      />

      <SnackBar
        open={stats.openSnackBar}
        handleClose={handleCloseSnackBar}
        severity={stats.snackBarSeverity}
        message={stats.snackBarMsg}
        autoHideDuration={7000}
      />
    </div>
  );
};
export default OptionsDropDown;
