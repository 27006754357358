import { styled } from "@mui/material/styles";
import { Tooltip } from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";

export const customizedStyledTooltip = {
  maxWidth: "250px",
  boxSizing: "border-box",
  padding: "10px",
  lineHeight: "16px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const MuiCustomizedTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses?.arrow}`]: {
    color: "rgba(97, 97, 97, 0.9)",
  },
  [`& .${tooltipClasses?.tooltip}`]: {
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "14px",
  },
}));

const CustomizedTooltip = ({
  title,
  placement,
  id,
  styles = { height: "40px", padding: "0px" },
  children,
}) => {
  return (
    <MuiCustomizedTooltip
      title={title}
      arrow
      placement={placement}
      id={id}
      styles={styles}
      componentsProps={{
        tooltip: {
          sx: { ...styles },
        },
      }}
      PopperProps={{ style: { zIndex: styles?.zIndex } }}
    >
      {children}
    </MuiCustomizedTooltip>
  );
};

export default CustomizedTooltip;
