import { Box, Typography, Divider, Button, Collapse } from "@mui/material";
import "./chat.scss";
import dummyImage from "images/shared-images/image-placeholder.svg";
import dummyVideo from "images/shared-images/video-placeholder.svg";
import moment from "moment";
import { useIntl } from "react-intl";
import { useEffect, useRef, useState } from "react";
import {
  getTimeZone,
  CheckValueLocale,
  sanitizeEngagementChatMessage,
} from "utils/helpers";
import ReplyModalChat from "../../replyModal/replyModalChat";
import { sanitize } from "dompurify";
import VideoFrame from "react-video-thumbnail";
import {
  faSpinnerThird,
  faExternalLink,
} from "@fortawesome/pro-regular-svg-icons";
import { faPlay, faSparkles } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Link } from "react-router-dom";
import ezgif from "images/engagements-images/ezgif.gif";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import EngagementsController from "services/controllers/engagementsController";
import TranslationRating from "../translationRating/translationRating";
import StoryReplayPreview from "components/conversation/StoryReplayPreview";
import DeletedMessagePreview from "components/conversation/DeletedMessagePreview";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import LucButton from "shared/lucButton/lucButton";
import { useDispatch, useSelector } from "react-redux";
import {
  slaData,
  clearSla,
} from "utils/redux/features/Engagements/EngagementsSlice.js";
import { MediaPreviewer } from "../../mediaPreviewer/mediaPreviewer";
import { ReactComponent as AttachmentPlaceholderIcon } from "images/engagements-images/attachment-placeholder.svg";
const Chat = ({
  selectedCard,
  itemSelected,
  setSelectedCardIteractionID,
  headerData,
  setPage,
  page,
  setOpenDetailsSideBar,
  setOpenDetailsSideBarReplay,
  setOpenSlaSideBar,
  text,
  setText,
  selectedValue,
  setSelectedValue,
  chatData,
  setChatData,
  setOpenSidebar,
  setCheckAiSuggestions,
  checkAiSuggestions,
  openSidebar,
  handelOpenSidebar,
  translationActive,
  setTranslationActive,
  translationSource,
  setTranslationSource,
  translationTarget,
  setTranslationTarget,
  stats,
  setStats,
  languagesArraySource,
  languagesArrayTarget,
  openDetailsSideBarReplay,
  savedSuggTab,
  aiRepsTab,
  savedSuggTabDate,
  isCashedReplays,
  setIsCashedReplays,
  setAiReplaysRefresh,
  isSidebarsOpened,
  showAllIcon,
  aiAgentList,
  setRefresh,
  setOpenSnackBarAiAgent,
  setSnackBarDataAiAgent,
  setInjectedMessage,
  aiAgentId,
  replyBoxInputTextRef,
  slaKey,
}) => {
  const intl = useIntl();
  const [showMediaPreviewer, setShowMediaPreviewer] = useState(false);
  const [media, setMedia] = useState([]);
  const [statusLogs, setStatusLogs] = useState([]);
  const [mainDates, setMainDates] = useState({});
  const [loadMore, setLoadMore] = useState(false);
  const [stopPaginatin, setStopPaginatin] = useState(false);
  const [scroll, setScroll] = useState(100);
  const [openTranslationCollapse, setOpenTranslationCollapse] = useState({});
  const [openReplyCollapse, setOpenReplyCollapse] = useState({});
  const [translationStatus, setTranslationStatus] = useState({});
  const [translationButtonLoading, setTranslationButtonLoading] = useState({});
  const [translationButtonResult, setTranslationButtonResult] = useState({});
  const [translateStatus, setTranslateStatus] = useState(false);
  const [deletedMessagesIds, setDeletedMesssagesIds] = useState([]);
  const dispatch = useDispatch();
  const reduxSlaData = useSelector((state) => state?.engagements?.sla);
  let companyTimeZone = window.localStorage.companyInfo;
  const handleImageError = (event) => {
    event.target.src = dummyVideo;
  };
  useEffect(() => {
    if (selectedCard?.conversation?.length) {
      let status_logs = selectedCard?.system_replies?.status_logs?.length
        ? selectedCard?.system_replies?.status_logs?.sort(function (a, b) {
            return new Date(a?.created_at) - new Date(b?.created_at);
          })
        : [];
      setStatusLogs(status_logs?.length ? [...status_logs] : []);
      setChatData([...selectedCard?.conversation?.reverse(), ...chatData]);
    } else {
      setStopPaginatin(true);
      setLoadMore(false);
    }
  }, [selectedCard]);
  const chatHeightBeforePagination = useRef(0);
  const div = document.getElementById("message-container");
  div?.addEventListener("scroll", (event) => {
    if (!div?.scrollTop) {
      chatHeightBeforePagination.current = div?.scrollHeight;
    }
    setScroll(div?.scrollTop);
  });

  useEffect(() => {
    if (!stopPaginatin) {
      // here we handle pagination for chat data in all datasources dm only
      let hasMorePages = true;
      hasMorePages = page < selectedCard?.pages;
      if (hasMorePages && !scroll && !loadMore && chatData?.length) {
        setLoadMore(true);
        setPage(page + 1);
      }
    }
  }, [scroll]);
  useEffect(() => {
    let dates = {};
    setMainDates({ ...dates });
    //here we handle sla counter to be dynamic , because sla counters effected by any change at masseges data
    let slaObj = { ...reduxSlaData };
    let slaSelectedObj = {
      ...slaObj[
        `${itemSelected?.tweet_id ? itemSelected?.tweet_id : itemSelected?.id}-${itemSelected?.tracker_id}`
      ],
    };
    slaSelectedObj.cardText = chatData[chatData?.length - 1]?.content?.text;
    slaObj[
      `${itemSelected?.tweet_id ? itemSelected?.tweet_id : itemSelected?.id}-${itemSelected?.tracker_id}`
    ] = {
      ...slaSelectedObj,
    };
    dispatch(slaData(slaObj));
    if (slaSelectedObj?.slaId) {
      slaSelectedObj.lastMsgDirecton =
        chatData[chatData?.length - 1]?.direction;

      //here we check if frt counter is live
      if (!slaSelectedObj?.frtSec) {
        /*check if last msg direction === "outbound" to stop frt live counter and update frtSec to 
        be diferance between card created at date and last msg date*/
        if (chatData[chatData?.length - 1]?.direction === "outbound") {
          slaSelectedObj.frtSec =
            chatData[chatData?.length - 1]?.created_at -
            itemSelected?.created_at;
          slaObj[
            `${itemSelected?.tweet_id ? itemSelected?.tweet_id : itemSelected?.id}-${itemSelected?.tracker_id}`
          ] = {
            ...slaSelectedObj,
          };
          dispatch(slaData(slaObj));
        }
      } else {
        //here we handle nrt counter
        /*we save nrt as alist of blocks if time_value is falsy this block well be live, if last msg
        direction === "outbound" this mean if last block of nrt list is live we will stop it,if last msg
        direction === "inbound" this mean we add new block as alast block and this will be live  */
        if (
          chatData[chatData?.length - 1]?.direction === "outbound" &&
          slaSelectedObj?.nrtList?.length &&
          !slaSelectedObj?.nrtList?.[0]?.time_value
        ) {
          let nrtArr = [
            {
              created_at: chatData[chatData?.length - 1]?.created_at,
              time_value:
                chatData[chatData?.length - 1]?.created_at -
                chatData[chatData?.length - 2]?.created_at,
            },
            ...slaSelectedObj?.nrtList?.slice(
              1,
              slaSelectedObj?.nrtList?.length,
            ),
          ];
          slaSelectedObj.nrtList = [...nrtArr];
          slaSelectedObj.nrtSec = nrtArr?.[0]?.time_value;
          slaSelectedObj.nrtDate = nrtArr?.[0]?.created_at;
          slaObj[
            `${itemSelected?.tweet_id ? itemSelected?.tweet_id : itemSelected?.id}-${itemSelected?.tracker_id}`
          ] = {
            ...slaSelectedObj,
          };
          dispatch(slaData(slaObj));
        } else if (chatData[chatData?.length - 1]?.direction === "inbound") {
          if (
            slaSelectedObj?.nrtList?.length &&
            !slaSelectedObj?.nrtList?.[0]?.time_value
          ) {
            let nrtArr = [
              {
                created_at: chatData[chatData?.length - 1]?.created_at,
                time_value: null,
              },
              ...slaSelectedObj?.nrtList?.slice(
                1,
                slaSelectedObj?.nrtList?.length,
              ),
            ];
            slaSelectedObj.nrtList = [...nrtArr];
            slaSelectedObj.nrtSec = nrtArr?.[0]?.time_value;
            slaSelectedObj.nrtDate = nrtArr?.[0]?.created_at;
            slaObj[
              `${itemSelected?.tweet_id ? itemSelected?.tweet_id : itemSelected?.id}-${itemSelected?.tracker_id}`
            ] = {
              ...slaSelectedObj,
            };
            dispatch(slaData(slaObj));
          } else {
            let nrtArr = [
              {
                created_at: chatData[chatData?.length - 1]?.created_at,
                time_value: null,
              },
              ...(slaSelectedObj?.nrtList?.length && slaSelectedObj?.nrtList
                ? slaSelectedObj?.nrtList
                : []),
            ];
            slaSelectedObj.nrtList = [...nrtArr];
            slaSelectedObj.nrtSec = nrtArr?.[0]?.time_value;
            slaSelectedObj.nrtDate = nrtArr?.[0]?.created_at;
            slaObj[
              `${itemSelected?.tweet_id ? itemSelected?.tweet_id : itemSelected?.id}-${itemSelected?.tracker_id}`
            ] = {
              ...slaSelectedObj,
            };
            dispatch(slaData(slaObj));
          }
        }
      }
    }

    // handle the main date for grop of masseges
    /* ex: if we have first and second massage created at for them at same day we save at mainDates object
    state {[index of first massage]:"date of this day"} */
    chatData?.map((item, index) => {
      const date = moment.unix(item?.created_at).utc().format("DD/MM/YYYY");
      let check = false;
      Object?.keys(dates)?.forEach(function (key, index) {
        if (dates[key] === date) {
          check = true;
        } else {
          check = false;
        }
      });
      if (!check) {
        dates[index] = date;
      }
    });
    setMainDates({ ...dates });
    if (!loadMore) {
      // at first render make scroll to last massage
      const div = document.getElementById("message-container");
      div.scrollTo({
        top: div.scrollHeight,
        //behavior: "smooth",
      });
    } else {
      const div = document.getElementById("message-container");
      div.scrollTo({
        top: div.scrollHeight - chatHeightBeforePagination.current,
      });
      setLoadMore(false);
    }
  }, [chatData]);

  useEffect(() => {
    setIsCashedReplays(
      chatData[chatData?.length - 1]?.created_at < savedSuggTabDate,
    );
  }, [savedSuggTabDate, chatData]);

  useEffect(() => {
    if (
      !(
        selectedCard?.status === "closed" ||
        selectedCard?.system_replies?.status === "complete" ||
        headerData?.paused === 1
      ) &&
      (savedSuggTab || aiRepsTab)
    ) {
      handelOpenSidebar();
    }
  }, [selectedCard, headerData, savedSuggTab, aiRepsTab]);

  const dateWithTimeZone = (date) => {
    let unixDate = moment(date).unix();
    let intialDate = moment
      .unix(unixDate)
      .format("YYYY/MM/DD hh:mm a")
      .split(" ");
    let dateText = `${intialDate[0]} - ${intialDate[1]} ${CheckValueLocale(
      intialDate[2] == "pm" ? "hashtag_period_pm" : "hashtag_period_am",
      "",
      {},
      intl,
    )} ${
      companyTimeZone >= 0
        ? `(${CheckValueLocale("gmt", "", {}, intl)} +${companyTimeZone})`
        : `(${CheckValueLocale("gmt", "", {}, intl)} ${companyTimeZone})`
    }`;
    return dateText;
  };

  const handleVideoError = (event) => {
    event.target.src = dummyVideo;
  };

  const translationButtonHandler = (text, index) => {
    setTranslationStatus({
      ...translationStatus,
      [index]: true,
    });
    setTranslationButtonLoading({
      ...translationButtonLoading,
      [index]: true,
    });
    const queryData = {
      text,
      target_language: translationTarget,
      source_language:
        translationSource !== "auto_detect" ? translationSource : null,
      product_id: window.localStorage.getItem("engagementsProductId"),
    };
    EngagementsController.detectTranslate(queryData).then((res) => {
      if (!res?.errorMsg) {
        setTranslationButtonResult({
          ...translationButtonResult,
          [index]: res?.data,
        });
        setOpenTranslationCollapse({
          ...openTranslationCollapse,
          [index]: true,
        });
      } else {
        setTranslationButtonResult({
          ...translationButtonResult,
          [index]: null,
        });
        setTranslationStatus({
          ...translationStatus,
          [index]: false,
        });
        setStats({
          ...stats,
          open: true,
          title: CheckValueLocale("request_access_error_heading", "", {}, intl),
          body: CheckValueLocale("request_access_error_desc", "", {}, intl),
          severity: "error",
        });
        setTimeout(() => {
          setStats({
            ...stats,
            open: false,
          });
        }, 7000);
      }
      setTranslationButtonLoading({
        ...translationButtonLoading,
        [index]: false,
      });
    });
  };

  const translationCollapseHandler = (index) => {
    setOpenTranslationCollapse({
      ...openTranslationCollapse,
      [index]: !openTranslationCollapse[index],
    });
  };
  const translationReplyHandler = (index) => {
    setOpenReplyCollapse({
      ...openReplyCollapse,
      [index]: !openReplyCollapse[index],
    });
  };

  useEffect(() => {
    setTranslationStatus({});
    setOpenTranslationCollapse({});
    setTranslationButtonLoading({});
    setTranslationButtonResult({});
  }, [translationTarget, translationSource]);

  const checkIfStoryNotEmpty = (story) => {
    try {
      if (story) {
        const storyObject = JSON.parse(story);
        if (Object.keys(storyObject).length) {
          return true;
        }
      }
    } catch (err) {
      // noop
    }
    return false;
  };
  const handleMediaClick = (media) => {
    if (media?.media_url) {
      setShowMediaPreviewer(true);
      setMedia([
        {
          type: media?.isVideo ? "video" : "image",
          url: media?.media_url,
        },
      ]);
    }
  };
  return (
    <Box className="main-engagment-chat">
      {showMediaPreviewer ? (
        <MediaPreviewer
          showMediaPreviewer={showMediaPreviewer}
          setShowMediaPreviewer={setShowMediaPreviewer}
          media={media}
        />
      ) : null}
      <Box
        className={
          selectedCard?.status === "closed" ||
          selectedCard?.system_replies?.status === "complete" ||
          headerData?.paused === 1
            ? "main-chat-contant no-rep"
            : "main-chat-contant"
        }
      >
        <Box
          className="message-container message-container-private chat-main"
          id="message-container"
        >
          {loadMore ? (
            <Box className="pagination-loader">
              {CheckValueLocale("loading", "", {}, intl)}
              <FontAwesomeIcon icon={faSpinnerThird} spin />
            </Box>
          ) : null}
          {chatData?.map((data, index) => {
            const isMessageDeleted = deletedMessagesIds?.includes(
              data?.message_id,
            );
            let media = data?.content?.media;
            let text = data?.content?.text;
            // in case of twitter, when the message contains media, twitter adding a tweet url at the end of the text message
            // we want remove the url from the text message
            if (media?.length && selectedCard?.data_source === "TWITTER") {
              const tweetURL = JSON.parse(media)?.url;
              if (tweetURL) {
                text = text?.replace(` ${tweetURL}`, "");
              }
            }
            if (selectedCard?.data_source !== "TWITTER" && media?.length) {
              media = JSON.parse(media);
              if (media[0]) {
                media[0].media_url = media[0]?.image_data?.url
                  ? media[0]?.image_data?.url
                  : media[0]?.video_data?.url;
                media[0].isVideo = media[0]?.video_data?.url ? true : false;
                media = { ...media[0] };
              }
              media = JSON.stringify(media);
            }
            return (
              <Box key={index}>
                {mainDates[index] ? (
                  <Box className="main-massage-date">
                    <Box>
                      {moment
                        .unix(data?.created_at)
                        .utc()
                        .format("DD MMMM YYYY")}
                    </Box>
                  </Box>
                ) : null}
                <Box
                  pt={2}
                  className={
                    data?.direction === "inbound"
                      ? "interaction-list-header chat-main-box clearfix"
                      : `interaction-list__header chat-main-box chat-main-box-right clearfix ${data?.is_temporary ? "temp-massage" : ""}`
                  }
                  key={data?.message_id}
                >
                  <span className="interaction-list-user-data">
                    <span className="label">{data?.name}</span>
                  </span>
                  <Box className={"chat-message"}>
                    {isMessageDeleted ? (
                      <DeletedMessagePreview />
                    ) : data?.content?.url_temporary ? (
                      <Box className="chat-message-media temp-media">
                        <Box className="temp-interactions-media">
                          <AttachmentPlaceholderIcon />
                          <Typography
                            className="temp-media-text"
                            component="span"
                          >
                            {CheckValueLocale(
                              "engagement_temp_media_text",
                              "",
                              {},
                              intl,
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    ) : media?.length && JSON.parse(media)?.media_url ? (
                      <Box className="chat-message-media">
                        <Box className="single-interactions-media">
                          {data?.content?.isVideo ||
                          data?.content?.isVideo == "true" ||
                          JSON.parse(media)?.isVideo ||
                          JSON.parse(media)?.isVideo == "true" ? (
                            <Box
                              className="engagement-chat-message-media-video-container"
                              onClick={() =>
                                handleMediaClick(JSON.parse(media))
                              }
                            >
                              <video
                                width="228"
                                height="165"
                                controls={false}
                                className="engagement-chat-message-media-video"
                              >
                                <source
                                  src={JSON.parse(media)?.media_url}
                                  type="video/mp4"
                                />
                                <source
                                  src={JSON.parse(media)?.media_url}
                                  type="video/ogg"
                                />
                                <source
                                  src={JSON.parse(media)?.media_url}
                                  type="video/avi"
                                />
                                <img src={dummyVideo} />
                              </video>
                              <Box className="play-button-container">
                                <Box className="play-button">
                                  <FontAwesomeIcon icon={faPlay} />
                                </Box>
                              </Box>
                            </Box>
                          ) : (
                            <img
                              width="228"
                              height="165"
                              className="engagement-chat-message-media-image"
                              src={JSON.parse(media)?.media_url}
                              onClick={() =>
                                handleMediaClick(JSON.parse(media))
                              }
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                let fixItem = JSON.parse(media);
                                if (
                                  fixItem.type == "photo" ||
                                  fixItem?.isVideo == false
                                ) {
                                  currentTarget.src = dummyImage;
                                } else {
                                  fixItem.isVideo = true;
                                  let dataArr = [...chatData];
                                  dataArr[index] = {
                                    ...dataArr[index],
                                    content: {
                                      ...dataArr[index]?.content,
                                      media: JSON.stringify(fixItem),
                                    },
                                  };
                                  setChatData([...dataArr]);
                                }
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                    ) : null}
                    {isMessageDeleted ? null : checkIfStoryNotEmpty(
                        data?.content?.story,
                      ) ? (
                      <StoryReplayPreview data={data} isEngagement={true} />
                    ) : text && text !== "" ? (
                      <>
                        <p
                          className="data-content-text"
                          dangerouslySetInnerHTML={{
                            __html: sanitizeEngagementChatMessage(text),
                          }}
                        />
                        {translationActive && !data?.original_replay ? (
                          <Box className="card-translation-wrapper">
                            <span
                              id="engagements-dm-translation-btn"
                              className="translation-button"
                              onClick={() =>
                                translationButtonHandler(text, index)
                              }
                            >
                              {CheckValueLocale("translate_from", "", {}, intl)}{" "}
                              (
                              {CheckValueLocale(
                                translationSource,
                                "",
                                {},
                                intl,
                              )}
                              ) {CheckValueLocale("to_lang", "", {}, intl)} (
                              {CheckValueLocale(
                                translationTarget,
                                "",
                                {},
                                intl,
                              )}
                              )
                            </span>
                            {translationStatus[index] ? (
                              <Box className="card-translation-box">
                                {translationButtonLoading[index] ? (
                                  <span className="translation-loading">
                                    {CheckValueLocale(
                                      "loading_please_wait",
                                      "",
                                      {},
                                      intl,
                                    )}
                                  </span>
                                ) : (
                                  <Box className="card-translation-collapse">
                                    <Box className="collapse-heading">
                                      <span className="translation-lang">
                                        {CheckValueLocale(
                                          "from_lang",
                                          "",
                                          {},
                                          intl,
                                        )}
                                        <span>
                                          {" "}
                                          {CheckValueLocale(
                                            translationButtonResult[index]
                                              ?.source_language,
                                            "",
                                            {},
                                            intl,
                                          )}{" "}
                                        </span>
                                        {CheckValueLocale(
                                          "to_lang",
                                          "",
                                          {},
                                          intl,
                                        )}
                                        <span>
                                          {" "}
                                          {CheckValueLocale(
                                            translationTarget,
                                            "",
                                            {},
                                            intl,
                                          )}{" "}
                                        </span>
                                      </span>
                                      <span
                                        className="arrow-icon-translation"
                                        onClick={() =>
                                          translationCollapseHandler(index)
                                        }
                                      >
                                        {openTranslationCollapse[index] ? (
                                          <ExpandMore />
                                        ) : (
                                          <ExpandLess />
                                        )}
                                      </span>
                                    </Box>
                                    <Collapse
                                      in={openTranslationCollapse[index]}
                                    >
                                      <span className="translation-text">
                                        {
                                          translationButtonResult[index]
                                            ?.translation
                                        }
                                      </span>
                                      <TranslationRating
                                        translationSource={
                                          translationButtonResult[index]
                                            ?.source_language
                                        }
                                        translationTarget={translationTarget}
                                        translation={
                                          translationButtonResult[index]
                                            ?.translation
                                        }
                                        selectedCard={selectedCard}
                                        engagementId={data?.message_id}
                                      />
                                    </Collapse>
                                  </Box>
                                )}
                              </Box>
                            ) : null}
                          </Box>
                        ) : null}
                        {data?.original_replay ? (
                          <Box className="card-translation-box">
                            <Box className="card-translation-collapse">
                              <Box className="collapse-heading">
                                <span className="translation-lang">
                                  <span>
                                    {CheckValueLocale(
                                      "your_original_replay",
                                      "",
                                      {},
                                      intl,
                                    )}
                                  </span>
                                </span>
                                <span
                                  className="arrow-icon-translation"
                                  onClick={() => translationReplyHandler(index)}
                                >
                                  {!openReplyCollapse[index] ? (
                                    <ExpandMore />
                                  ) : (
                                    <ExpandLess />
                                  )}
                                </span>
                              </Box>
                              <Collapse in={!openReplyCollapse[index]}>
                                <span className="translation-text">
                                  {data?.original_replay}
                                </span>
                              </Collapse>
                            </Box>
                          </Box>
                        ) : null}
                      </>
                    ) : null}
                  </Box>
                  <Box
                    className={
                      data?.direction === "inbound"
                        ? "date-chat"
                        : "date-chat right"
                    }
                  >
                    <span className="time-zone-space">
                      {moment.unix(data?.created_at).utc().format("LT")}
                    </span>
                    {data?.system_replies?.replier_info?.email ? (
                      <Box className="replied-by-email">
                        <Typography className="card-themes-label">
                          {`${CheckValueLocale("replied_by", "", {}, intl)} : `}
                        </Typography>
                        <Typography className="card-themes-replied-by">
                          {data?.system_replies?.replier_info?.email}
                        </Typography>
                      </Box>
                    ) : null}
                  </Box>
                </Box>
                {statusLogs?.length &&
                new Date(statusLogs[statusLogs?.length - 1]?.created_at) >=
                  new Date(data?.created_at) &&
                chatData[index + 1] ? (
                  new Date(statusLogs[statusLogs?.length - 1]?.created_at) <=
                  new Date(chatData[index + 1]?.created_at)
                ) : true ? (
                  statusLogs[statusLogs?.length - 1]?.status == "complete" ? (
                    <Box className="main-post-state">
                      <Box>
                        {CheckValueLocale(
                          "marked_as_completed",
                          "",
                          {
                            name: statusLogs[statusLogs?.length - 1]
                              ?.user_email,
                            date: dateWithTimeZone(
                              statusLogs[statusLogs?.length - 1]?.created_at,
                            ),
                          },
                          intl,
                        )}{" "}
                        {selectedCard?.system_replies?.completion_reason ===
                        "other" ? (
                          <Box
                            component={"span"}
                            className="completion-reason-span"
                          >
                            {CheckValueLocale(
                              selectedCard?.system_replies?.completion_reason,
                              "",
                              {},
                              intl,
                            )}
                            {` (${CheckValueLocale(
                              selectedCard?.system_replies?.other_reason,
                              "",
                              {},
                              intl,
                            )})`}
                          </Box>
                        ) : (
                          CheckValueLocale(
                            selectedCard?.system_replies?.completion_reason,
                            "",
                            {},
                            intl,
                          )
                        )}
                      </Box>
                    </Box>
                  ) : statusLogs[statusLogs?.length - 1]?.status == "active" ? (
                    <Box className="main-post-state">
                      <Box>
                        {CheckValueLocale(
                          "marked_as_active",
                          "",
                          {
                            name: statusLogs[statusLogs?.length - 1]
                              ?.user_email,
                            date: dateWithTimeZone(
                              statusLogs[statusLogs?.length - 1]?.created_at,
                            ),
                          },
                          intl,
                        )}
                      </Box>
                    </Box>
                  ) : statusLogs[statusLogs?.length - 1]?.status == "reopen" ? (
                    <Box className="main-post-state">
                      <Box>
                        {CheckValueLocale(
                          "marked_as_reopen",
                          "",
                          {
                            name: statusLogs[statusLogs?.length - 1]
                              ?.user_email,
                            date: dateWithTimeZone(
                              statusLogs[statusLogs?.length - 1]?.created_at,
                            ),
                          },
                          intl,
                        )}
                      </Box>
                      <Divider />
                    </Box>
                  ) : null
                ) : null}
              </Box>
            );
          })}
          {!isCashedReplays && openSidebar ? (
            <Box className="cashed-replays">
              <Box className="cashed-replays-msg">
                {CheckValueLocale("cashed_msg", "", {}, intl)}
              </Box>
              <LucButton
                className="update-ai-cashed"
                onClick={() => setAiReplaysRefresh(true)}
                variant="outline"
                startIcon={<FontAwesomeIcon icon={faSparkles} />}
              >
                {CheckValueLocale("update_ai", "", {}, intl)}
              </LucButton>
            </Box>
          ) : null}
        </Box>
      </Box>
      {selectedCard?.status === "closed" ||
      selectedCard?.system_replies?.status === "complete" ||
      headerData?.paused === 1 ? (
        headerData?.paused === 1 ? (
          <Box className="main-posts-footer no-reply-footer chat">
            <Box className="main-no-reply">
              <Box
                className={
                  headerData?.paused === 1
                    ? "main-sent-reply paused"
                    : "main-sent-reply"
                }
              >
                {headerData?.paused === 1 ? (
                  <ErrorOutlineIcon />
                ) : (
                  <CancelIcon />
                )}
                {CheckValueLocale(
                  headerData?.paused !== 1
                    ? "conversation_closed"
                    : headerData?.type == "monitor"
                      ? "paused_monitors_header"
                      : "paused_channels_header",
                  "",
                  {},
                  intl,
                )}
              </Box>
              <Typography className="no-reply-class">
                {CheckValueLocale(
                  headerData?.paused !== 1
                    ? "no_reply_text_dm"
                    : headerData?.type == "monitor"
                      ? "paused_monitors_contant"
                      : "paused_channels_contant",
                  "",
                  { name: headerData?.monitor_name },
                  intl,
                )}
                {headerData?.paused == 1 ? (
                  <Link
                    target="_blank"
                    to={
                      headerData?.type == "monitor"
                        ? "/social/monitor_list"
                        : "/manage-channels/list"
                    }
                    className="paused-rediraction"
                  >
                    {CheckValueLocale(
                      headerData?.type == "monitor"
                        ? "monitors_list"
                        : "manage_channels",
                      "",
                      {},
                      intl,
                    )}
                    <FontAwesomeIcon icon={faExternalLink} />
                  </Link>
                ) : null}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box>
            {(!selectedCard?.closing_reason ||
              selectedCard?.closing_reason == "closed_by_user") &&
            (selectedCard?.data_source !== "TWITTER" ||
              (selectedCard?.data_source == "TWITTER" &&
                selectedCard?.system_replies?.status === "complete")) ? (
              <Box className="main-posts-footer no-reply-footer">
                <Box className="main-no-reply">
                  <Box className={"main-sent-reply"}>
                    <CheckCircleIcon />
                    {CheckValueLocale("mark_completed", "", {}, intl)}
                  </Box>
                  <Typography className="no-reply-class">
                    {CheckValueLocale("mark_completed_msg", "", {}, intl)}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box className="closed-by-time">
                <Box className="closed-by-time-main-warning">
                  <WarningIcon />
                  <Box className="closed-by-time-prag">
                    <Box className="closed-by-time-title">
                      {CheckValueLocale(
                        selectedCard?.data_source !== "TWITTER"
                          ? "can_not_replay"
                          : "can_not_replay_twitter",
                        "",
                        {},
                        intl,
                      )}
                    </Box>
                    <Box
                      className="closed-by-time-msg"
                      dangerouslySetInnerHTML={{
                        __html: sanitize(
                          CheckValueLocale(
                            selectedCard?.data_source !== "TWITTER"
                              ? "can_not_replay_msg"
                              : "can_not_replay_msg_twitter",
                            "",
                            {},
                            intl,
                          ),
                        ),
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        )
      ) : (
        <Box className="main-posts-footer-parant">
          {!openSidebar && !aiAgentId ? (
            <Button
              className="ai-suggestions"
              onClick={() => handelOpenSidebar()}
            >
              <img src={ezgif} />
              {CheckValueLocale("ai_suggestions", "", {}, intl)}
            </Button>
          ) : null}
          <Box className="main-posts-footer">
            <ReplyModalChat
              setOpenDetailsSideBar={setOpenDetailsSideBar}
              setOpenDetailsSideBarReplay={setOpenDetailsSideBarReplay}
              setOpenSlaSideBar={setOpenSlaSideBar}
              selectedCard={selectedCard}
              setChatData={setChatData}
              setStatusLogs={setStatusLogs}
              setSelectedCardIteractionID={setSelectedCardIteractionID}
              chatData={chatData}
              text={text}
              setText={setText}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              setOpenSidebar={setOpenSidebar}
              setCheckAiSuggestions={setCheckAiSuggestions}
              translationActive={translationActive}
              setTranslationActive={setTranslationActive}
              translationSource={translationSource}
              setTranslationSource={setTranslationSource}
              translationTarget={translationTarget}
              setTranslationTarget={setTranslationTarget}
              stats={stats}
              setStats={setStats}
              languagesArraySource={languagesArraySource}
              languagesArrayTarget={languagesArrayTarget}
              translateStatus={translateStatus}
              setTranslateStatus={setTranslateStatus}
              headerData={headerData}
              setDeletedMesssagesIds={setDeletedMesssagesIds}
              openSidebar={openSidebar}
              openDetailsSideBarReplay={openDetailsSideBarReplay}
              isSidebarsOpened={isSidebarsOpened}
              showAllIcon={showAllIcon}
              aiAgentList={aiAgentList}
              setRefresh={setRefresh}
              setOpenSnackBarAiAgent={setOpenSnackBarAiAgent}
              setSnackBarDataAiAgent={setSnackBarDataAiAgent}
              setInjectedMessage={setInjectedMessage}
              aiAgentId={aiAgentId}
              replyBoxInputTextRef={replyBoxInputTextRef}
              reduxSlaKeys={slaKey}
              itemSelected={itemSelected}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default Chat;
