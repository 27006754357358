import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CheckValueLocale, handlelUserRoles, isEmptyObj } from "utils/helpers";
import { useIntl } from "react-intl";
import SurveyController from "services/controllers/surveyController";

const SurveyFunctions = () => {
  const intl = useIntl();
  const editRef = useRef(null);
  let history = useHistory();
  const urlParams = useParams();
  const id = urlParams?.id;
  const [isLoading, setIsLoading] = useState(true);
  const [saveChangesFlag, setSaveChangesFlag] = useState(false);
  const [logoData, setLogoData] = useState({
    logoUploaded: false,
    logoUploading: false,
  });
  const [surveyData, setSurveyData] = useState({});
  const [showPoweredBy, setShowPoweredBy] = useState(false);
  const [sqIndex, setSQIndex] = useState(1); // Selected question Index
  const [ssIndex, setSSIndex] = useState(1); // Survey selected section Index
  const [selectedCountryCode, setSelectedCountryCode] = useState("SA");
  const [openPublishSurveyModal, setOpenPublishSurveyModal] = useState(false);
  const [preview, setPreview] = useState(false);
  const [operationPerformed, setOperationPerformed] = useState("");
  const [count, setCount] = useState(0);
  let surveyTitleLocales = [
    "Untitled Survey",
    "Survey Description (Optional)",
    "نموذج بدون عنوان",
    "وصف الاستبيان (اختياري)",
  ];
  const [isLucidyaDomain, setIsLucidyaDomain] = useState(false);
  const [customDomain, setCustomDomain] = useState("");
  const [customDomainStepLoading, setCustomDomainStepLoading] = useState(false);
  const [customDomainStep, setCustomDomainStep] = useState(false);
  const [surveyLimitReach, setSurveyLimitReach] = useState(false);
  const [publishFlag, setPublishFlag] = useState(true);
  const [isPublished, setIsPublished] = useState(false);
  const [multiRes, setMultiRes] = useState(false);
  const [isSurveyEnabled, setIsSurveyEnabled] = useState(false);
  const [isSkeipped, setIsSkeipped] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [filteredSections, setFilteredSections] = useState([]);
  const [surveySections, setSurveySections] = useState([]);
  const [emptySection, setEmptySection] = useState(null);
  const [proceedError, setProceedError] = useState(null);
  const [afterSecError, setAfterSecError] = useState(null);
  const [secTitleErr, setSecTitleErr] = useState(null);
  const [snackBar, setSnackBar] = useState({
    open: false,
    title: "",
    msg: "",
    severity: "success",
  });

  const handleSectionDir = (value, sIndex) => {
    filteredSections[sIndex].text_direction = value;
    setSurveySections([...surveySections]);
  };
  useEffect(() => {
    if (surveySections) {
      let filteredArray = surveySections?.filter(
        (obj) => obj?.is_deleted !== true,
      );
      setFilteredSections(filteredArray);
    }
  }, [surveySections]);

  useEffect(() => {
    if (filteredSections) {
      // Reset order key for all sections
      filteredSections?.map((sec, index) => (sec.order = index + 1));

      // Only one section set next_section -1 and disable proceed questions
      if (filteredSections?.length === 1) {
        filteredSections[0].next_section = -1;
        filteredSections[0]?.questions
          ?.filter((question) => question?.is_deleted !== true)
          ?.forEach((question) => {
            if (filteredSections[0]?.name?.length < 1)
              filteredSections[0].name = `${CheckValueLocale(
                "Untitled Section",
                "",
                {},
                intl,
              )} 1`;
            if (question?.is_proceed_on_answer === true) {
              question.is_proceed_on_answer = false;
              question?.question?.items?.forEach((item) => {
                delete item["next_section"];
              });
              setSurveySections([...surveySections]);
            }
          });
      }

      // Reset all errors to null after any edits
      setEmptySection(null);
      setProceedError(null);
      setAfterSecError(null);
      setSecTitleErr(null);
    }
  }, [filteredSections]);

  const newSection = {
    survey: id,
    name: `${CheckValueLocale("Untitled Section", "", {}, intl)} ${
      filteredSections?.length + 1
    }`,
    order: filteredSections?.length + 1,
    next_section: -1,
    is_deleted: false,
    questions: [],
    text_direction: intl?.locale == "ar" ? "rtl" : "ltr",
  };

  const handleNewSection = async () => {
    let lastSec = filteredSections[filteredSections?.length - 1];
    if (lastSec?.next_section === -1 || lastSec?.next_section === null) {
      lastSec.next_section = lastSec?.order + 1;
    }

    let newQuestions = [];
    newSection.questions = newQuestions;

    setSurveySections([...surveySections, newSection]);

    scrollToIdFunc(`section-header-${filteredSections?.length}`);
  };

  const handleDuplicateSection = (e, sIndex) => {
    e.stopPropagation();
    let newQues = [];
    filteredSections[sIndex]?.questions
      ?.filter((question) => question?.is_deleted !== true)
      ?.forEach((question) => {
        newQues?.push({
          question: { ...question?.question, editable: true },
          is_deleted: false,
          ...(question?.is_proceed_on_answer !== undefined && {
            is_proceed_on_answer: false,
          }),
          ...(question?.is_proceed_editable !== undefined && {
            is_proceed_editable: true,
          }),
        });
      });

    const oldSec = filteredSections[sIndex];
    const mainIndex = surveySections?.findIndex((sec) => sec === oldSec);
    const duplicated = {
      name: `${oldSec?.name} (${CheckValueLocale(
        "survey_copy",
        "",
        {},
        intl,
      )})`,
      survey: oldSec?.survey,
      is_deleted: false,
      order: oldSec?.order + 1,
      next_section:
        oldSec?.order === filteredSections?.length ? -1 : oldSec?.order + 2,
      questions: newQues,
    };

    filteredSections?.forEach((sec) => {
      if (sec?.next_section > oldSec?.order) sec.next_section += 1;
      sec?.questions
        ?.filter((question) => question?.is_deleted !== true)
        ?.forEach((question) => {
          question?.question?.items?.forEach((item) => {
            if (item?.next_section > oldSec?.order) item.next_section += 1;
          });
        });
    });
    surveySections?.splice(mainIndex + 1, 0, duplicated);
    setSurveySections([...surveySections]);
  };

  const handleDeleteSection = (e, sIndex) => {
    if (sIndex === 0) setSSIndex(1);
    else setSSIndex(sIndex);
    setSQIndex(1);
    // Reset all next_section with next_section -= 1 and next_section = order with null
    filteredSections?.forEach((sec, secIndex) => {
      if (sec?.next_section === sIndex + 1) sec.next_section = null;
      else if (sec?.next_section > sIndex + 1) sec.next_section -= 1;
      sec?.questions
        ?.filter((ques) => ques?.is_deleted !== true)
        ?.forEach((ques) => {
          if (ques?.is_proceed_on_answer)
            ques?.question?.items?.forEach((item) => {
              if (item?.next_section === sIndex + 1) item.next_section = null;
              else if (item?.next_section > sIndex + 1) item.next_section -= 1;
            });
        });
    });
    filteredSections[sIndex].is_deleted = true;
    filteredSections[sIndex]?.questions?.forEach((question) => {
      question.is_deleted = true;
    });
    setSurveySections([...surveySections]);
  };

  const handleSectionTitle = (e, sIndex) => {
    filteredSections[sIndex].name = e.target.value;
    setSurveySections([...surveySections]);
  };

  const handleQuestionTitle = (e, sIndex, qIndex, value, setValue) => {
    setValue({ ...value, title: e.target.value });
    filteredSections[sIndex].questions.filter(
      (ques) => ques?.is_deleted !== true,
    )[qIndex].question.text = e.target.value;
    setSurveySections([...surveySections]);
  };

  const handleQuestionDesc = (e, sIndex, qIndex, value, setValue) => {
    setValue({ ...value, description: e.target.value });
    filteredSections[sIndex].questions.filter(
      (ques) => ques?.is_deleted !== true,
    )[qIndex].question.description = e.target.value;
    setSurveySections([...surveySections]);
  };

  const handleDeleteQuestion = (sIndex, qIndex) => {
    if (
      "question_id" in
      filteredSections[sIndex].questions.filter(
        (ques) => ques?.is_deleted !== true,
      )[qIndex]
    )
      filteredSections[sIndex].questions.filter(
        (ques) => ques?.is_deleted !== true,
      )[qIndex].is_deleted = true;
    else filteredSections[sIndex].questions.splice(qIndex, 1);
    setSurveySections([...surveySections]);
    if (qIndex === 0) setSQIndex(1);
    if (editRef) scrollToIdFunc(`section-${sIndex}-question-${qIndex - 1}`);
  };

  const handleChangeProceed = (e, sIndex, qIndex, itemIndex) => {
    filteredSections[sIndex].questions.filter(
      (question) => question?.is_deleted !== true,
    )[qIndex].question.items[itemIndex].next_section = e.target.value;
    setSurveySections([...surveySections]);
  };

  const handleCardClick = (sIndex, qIndex) => {
    setSSIndex(sIndex + 1);
    setSQIndex(qIndex + 1);
  };

  const scrollToIdFunc = (id) => {
    setTimeout(() => {
      const parentElement = editRef.current;
      const childElement = document.getElementById(id);
      if (parentElement && childElement) {
        const parentRect = parentElement?.getBoundingClientRect();
        const childRect = childElement?.getBoundingClientRect();
        const scrollTop =
          childRect?.top - parentRect?.top + parentElement?.scrollTop;
        parentElement.scrollTo({ top: scrollTop, behavior: "smooth" });
      }
    }, 100);
  };

  useEffect(() => {
    if (surveyData)
      if (surveyData?.title?.trim()?.length < 1) {
        setSaveChangesFlag(false);
        setPublishFlag(false);
        return;
      } else {
        setSaveChangesFlag(true);
        setPublishFlag(true);
      }

    // Validation of sections and next_sections
    if (filteredSections)
      if (
        filteredSections?.length <= 1 &&
        filteredSections[0]?.questions?.filter(
          (question) => question?.is_deleted !== true,
        )?.length < 1
      ) {
        setSaveChangesFlag(false);
        setPublishFlag(false);
        return;
      } else {
        setSaveChangesFlag(true);
        setPublishFlag(true);
      }
  }, [surveyData, filteredSections]);

  const validateEmptySections = () => {
    setProceedError(null);
    setEmptySection(null);
    setAfterSecError(null);
    let hasError = false;
    filteredSections?.some((sec, sIndex) => {
      if (
        sec?.questions?.filter((question) => question?.is_deleted !== true)
          ?.length < 1
      ) {
        hasError = true;
        setEmptySection(sIndex);
        scrollToIdFunc(`section-header-${sIndex}`);
        setOpenPublishSurveyModal(false);
        return true;
      }
      return false;
    });
    return !hasError;
  };

  const validateProceedOnSave = () => {
    setEmptySection(null);
    setProceedError(null);
    setAfterSecError(null);
    let hasError = false;
    filteredSections?.some((sec, secIndex) => {
      return sec?.questions
        ?.filter((question) => question?.is_deleted !== true)
        .some((question, index) => {
          if (question?.is_proceed_on_answer === true)
            return question?.question?.items?.some((item) => {
              if (item?.next_section === null) {
                hasError = true;
                setSSIndex(secIndex + 1);
                setSQIndex(index + 1);
                setProceedError(index);
                scrollToIdFunc(`section-${secIndex}-question-${index}`);
                setSnackBar({
                  open: true,
                  severity: "error",
                  message: CheckValueLocale("proceed_error_msg", "", {}, intl),
                  title: CheckValueLocale("proceed_error_title", "", {}, intl),
                });
                setOpenPublishSurveyModal(false);
                return true;
              } else setProceedError(null);
              return false;
            });
          return false;
        });
    });
    return !hasError;
  };

  const validateAfterSecOnSave = () => {
    setEmptySection(null);
    setProceedError(null);
    setAfterSecError(null);
    let hasError = false;
    filteredSections?.some((sec, sIndex) => {
      if (sec?.next_section === null) {
        hasError = true;
        setSSIndex(sIndex + 1);
        setAfterSecError(sIndex);
        scrollToIdFunc(`section-footer-${sIndex}`);
        setOpenPublishSurveyModal(false);
        setSnackBar({
          open: true,
          severity: "error",
          title: CheckValueLocale("proceed_error_title", "", {}, intl),
          message: CheckValueLocale("after_section_error_msg", "", {}, intl),
        });
        return true;
      }
      return false;
    });
    return !hasError;
  };

  const validateEmptySecTitle = () => {
    setEmptySection(null);
    setProceedError(null);
    setAfterSecError(null);
    setSecTitleErr(null);
    let hasError = false;
    filteredSections?.some((sec, sIndex) => {
      if (sec?.name?.trim()?.length < 1) {
        hasError = true;
        setSQIndex(null);
        setSSIndex(sIndex + 1);
        setSecTitleErr(sIndex);
        scrollToIdFunc(`section-header-${sIndex}`);
        return true;
      }
      return false;
    });
    return !hasError;
  };

  const validateSecNameDuplication = () => {
    setEmptySection(null);
    setProceedError(null);
    setAfterSecError(null);
    setSecTitleErr(null);
    let hasError = false;
    const nameOccurrences = {};
    filteredSections?.forEach(
      (sec) =>
        (nameOccurrences[sec?.name] = (nameOccurrences[sec?.name] || 0) + 1),
    );
    const duplicatedNames = Object.keys(nameOccurrences)?.filter(
      (name) => nameOccurrences[name] > 1,
    );
    if (duplicatedNames?.length > 0) {
      hasError = true;
      setSnackBar({
        open: true,
        severity: "error",
        message: CheckValueLocale(
          "section_title_duplication_error_msg",
          "",
          {},
          intl,
        ),
      });
    }
    return !hasError;
  };

  // ----------------------------------------------------------------------

  useEffect(() => {
    if (!handlelUserRoles("SURVEY", "EDIT_SURVEY"))
      history.push("/survey/list");

    if (count === 1) getSurveysById(id);
    else setCount(count + 1);
  }, [count]);

  const getSurveysById = (id) => {
    SurveyController.getSurveysById(id).then((data) => {
      if (data !== undefined) {
        setSurveySections(
          data?.data?.sections?.length > 0
            ? data?.data?.sections
            : [newSection],
        );
        setSurveyLimitReach(data?.survey_limit_reach);
        setIsSurveyEnabled(data?.is_survey_panel_enabled);
        setIsSkeipped(data?.is_skipped);
        setCustomDomain(data?.custom_domain);
        setSurveyData(data?.data);
        setShowPoweredBy(data?.data?.show_powered_by);
        setLogoData({
          ...logoData,
          logoUploaded: data?.data?.logo ? true : false,
          logoImage: data?.data?.logo,
        });
        setIsPublished(data?.data?.published);
        setIsLoading(false);
        setSaveChangesFlag(false);
      }
    });
  };

  const handleSaveChanges = (newLogo, published, publishBtn) => {
    if (!validateEmptySections()) return;
    else if (!validateProceedOnSave()) return;
    else if (!validateAfterSecOnSave()) return;
    else if (!validateEmptySecTitle()) return;
    else if (!validateSecNameDuplication()) return;
    setSaveLoading(true);
    let survey = {};
    var formData = new FormData();
    if (published === true || isPublished) survey.published = true;
    else survey.published = false;
    survey.title = surveyData?.title?.trim();
    survey.subtitle = surveyData?.subtitle?.trim();
    survey.lang = surveyData?.lang;
    survey.lucidya_domain = isLucidyaDomain;
    survey.is_multiresponses = multiRes;
    survey.text_direction = surveyData?.text_direction;
    formData.append("survey", JSON.stringify(survey));
    formData.append("sections", JSON.stringify(surveySections));
    if (logoData?.logoImage && typeof logoData?.logoImage === "object")
      formData.append("logo", logoData?.logoImage);
    else if (logoData?.logoImage === "") formData.append("logo", "");
    handleSaveSurveyChanges(formData, published, publishBtn);
  };

  const handleSaveSurveyChanges = (formData, published, publishBtn) => {
    SurveyController.saveSurveyChanges(surveyData?.id, formData).then(
      (data) => {
        setCustomDomainStepLoading(true);
        if (data?.status === "success") {
          setSurveySections(
            data?.data?.sections?.length > 0
              ? data?.data?.sections
              : [newSection],
          );
          setCustomDomainStepLoading(false);
          if (published) setOpenPublishSurveyModal(true);
          setLogoData({
            ...logoData,
            logoUploaded: data?.data?.logo ? true : false,
            logoUploading: false,
            logoImage: data?.data?.logo,
          });
          setSurveyData(data?.data);
          setIsPublished(data?.data?.published);
          setSaveChangesFlag(false);
          setSaveLoading(false);
          if (publishBtn) setOpenPublishSurveyModal(true);
        } else {
          setSaveLoading(false);
          if (
            data?.errorMsg?.response?.data?.exception?.message ==
            "FILE_SIZE_LIMIT_EXCEED"
          ) {
            setSnackBar({
              open: true,
              message: CheckValueLocale("media_logo_size_limit", "", {}, intl),
              severity: "error",
            });
          } else if (
            data?.errorMsg?.response?.data?.exception?.message ==
            "UNSUPPORTED_FILE_TYPE"
          ) {
            setSnackBar({
              open: true,
              message: CheckValueLocale("unsupported_file_type", "", {}, intl),
              severity: "error",
            });
          }
        }
      },
    );
  };

  useEffect(() => {
    if (!isEmptyObj(surveyData)) {
      let titleValue = surveyTitleLocales?.includes(surveyData?.title)
        ? CheckValueLocale("Untitled Survey", "", {}, intl)
        : surveyData?.title;
      let subTitleValue = surveyTitleLocales?.includes(surveyData?.subtitle)
        ? CheckValueLocale("Survey Description (Optional)", "", {}, intl)
        : surveyData?.subtitle;
      setSurveyData({
        ...surveyData,
        title: titleValue,
        subtitle: subTitleValue,
      });
    }
  }, [intl?.locale, surveyData?.title, surveyData?.subtitle]);

  const handlePublishBtn = () => {
    if (saveChangesFlag) handleSaveChanges("", "", true);
    else setOpenPublishSurveyModal(true);
  };

  useEffect(() => {
    if (surveyData?.is_multiresponses)
      setMultiRes(surveyData?.is_multiresponses);
  }, [surveyData?.is_multiresponses]);

  const handleMultiRes = (e) => {
    setSaveChangesFlag(true);
    setMultiRes(e.target.checked);
    setSurveyData({ ...surveyData, is_multiresponses: e.target.checked });
  };

  return {
    id,
    isLoading,
    preview,
    setPreview,
    surveySections,
    logoData,
    surveyData,
    setSurveyData,
    showPoweredBy,
    saveChangesFlag,
    handlePublishBtn,
    surveyLimitReach,
    handleSaveChanges,
    handleDeleteQuestion,
    setLogoData,
    setSQIndex,
    sqIndex,
    ssIndex,
    setSSIndex,
    setOperationPerformed,
    multiRes,
    handleMultiRes,
    setSaveChangesFlag,
    handleCardClick,
    selectedCountryCode,
    setSelectedCountryCode,
    operationPerformed,
    setOpenPublishSurveyModal,
    setIsLucidyaDomain,
    customDomain,
    customDomainStepLoading,
    setCustomDomainStep,
    customDomainStep,
    isSurveyEnabled,
    isSkeipped,
    setIsSkeipped,
    openPublishSurveyModal,
    surveySections,
    setSurveySections,
    filteredSections,
    handleNewSection,
    handleDuplicateSection,
    handleDeleteSection,
    emptySection,
    setEmptySection,
    handleSectionTitle,
    handleQuestionTitle,
    handleQuestionDesc,
    scrollToIdFunc,
    publishFlag,
    handleChangeProceed,
    proceedError,
    afterSecError,
    secTitleErr,
    editRef,
    snackBar,
    setSnackBar,
    saveLoading,
    handleSectionDir,
  };
};

export default SurveyFunctions;
