import { useEffect, useState } from "react";
import { Container, Box } from "@mui/material";
import { useIntl } from "react-intl";
import ChooseCategoryStep from "./chooseCategoryStep";
import BotConfiguration from "./botConfiguration";
import SessionConfiguration from "./sessionConfiguration";
import SetUpChannelStep from "./setUpChannelStep";
import "./addChannel.scss";
import { useHistory } from "react-router-dom";
import Snackbar from "components/snackBar";
import CircularLoading from "components/circularProgress";
import { CheckValueLocale, getDatasourceId } from "utils/helpers/index";
import { genesysLastStepAuth } from "../manageChannels.hooks";
import { useAuthorizedMonitorType } from "../hooks/useAuthorizedMonitorType";
import { useCompanyDataCategories } from "../hooks/useCompanyDataCategories";
import { useMonitorsDataSources } from "../hooks/useMonitorsDataSources";
import { useDataSourceForCategory } from "../hooks/useDataSourceForCategory";
import { useAuthorizedAccounts } from "../hooks/useAuthorizedAccounts";
import { useCreateMonitor } from "../hooks/useCreateMonitor";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import {
  setChannelAdded,
  setChannelFailed,
} from "utils/redux/features/ManageChannels/ManageChannels";
import GoogleEmailPolicyAlert from "./googleEmailPolicyAlert";
import AddChannelPageHeader from "./addChannelPageHeader";
import AddChannelCustomStepper from "./addChannelCustomStepper";
import AddChannelFooter from "./addChannelFooter";
import AddChannelSocialListingSnackbar from "./addChannelSocialListingSnackbar";
import {
  addChannelFormikInitialValues,
  addChannelInitialSteps,
  addChannelSocialAccounts,
  addChannelSocialSteps,
  createMonitorModel,
  createMonitorOptions,
  handleSuccessErrorCreateChannel,
  selectedAccountInitialData,
} from "./addChannelUtils";
import AddChannelUpgradeIntegrationPopup from "./addChannelUpgradeIntegrationPopup";
import { addChannelFormikValidationSchema } from "../manageChannelValidation";

const defaultMonitorData = {
  ignoreTweets: [],
  customerCareVal: "",
};
const defaultMonitorError = {
  ignoreTweets: "",
  customerCareVal: "",
};

const AddChannel = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const { data: authorizedMonitorTypeResponse } = useAuthorizedMonitorType();
  const authorizedMonitorTypeData = authorizedMonitorTypeResponse?.data;
  const { data: companyDataCategoriesResponse } = useCompanyDataCategories();
  const companyDataCategoriesData = companyDataCategoriesResponse?.data;
  const { data: dataSourcesResponse, isError: isDataSourceError } =
    useMonitorsDataSources();
  const dataSourcesData = dataSourcesResponse?.data?.data;
  const genesysDataSourceId = getDatasourceId(dataSourcesData, "GENESYS")?.id;
  const tiktokDataSourceId = getDatasourceId(dataSourcesData, "TIKTOK")?.id;

  const [monitorErrors, setMonitorErrors] = useState(defaultMonitorError);
  const [monitorData, setMonitorData] = useState(defaultMonitorData);
  const [activeStep, setActiveStep] = useState(0);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [isIntegrationModalOpen, setIsIntegrationModalOpen] = useState(false);
  const [stats, setStats] = useState({
    activeDataSourceId: 0,
    activeDataSourceName: "",
  });
  const [selectedCategoryStats, setSelectedCategoryStats] = useState({});

  const {
    mutate: getCategoryDataSource,
    isPending: isDataSourceForCategoryLoading,
    data: categoryDataSourcesResponse,
    reset: resetCategoryDataSource,
  } = useDataSourceForCategory();
  const categoryDataSources = categoryDataSourcesResponse?.data;

  const {
    mutate: getAuthorizedAccountsMutation,
    isPending: isAuthorizedAccountsLoading,
    data: authorizedAccountsResponse,
    reset: resetAuthorizedAccounts,
  } = useAuthorizedAccounts();
  const authorizedAccounts = authorizedAccountsResponse?.data?.data;

  const [socialListeningSuccess, setSocialListeningSuccess] = useState(null);
  const authorizedMonitorType = authorizedMonitorTypeData;
  const [socialMediaDataSource, setSocialMediaDataSource] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [genesysCodeLoading, setGenesysCodeLoading] = useState(false);
  const [isEmptyAccountScreen, setIsEmptyAccountScreen] = useState(false);

  const { mutate: createMonitor, isPending: isCreateMonitorLoading } =
    useCreateMonitor();

  const [genesysStatus, setGenesysStatus] = useState("");
  const [failSnackBarData, setFailSnackBarData] = useState({
    severity: "",
    message: "",
    title: "",
  });
  const [openSnackBarAllSource, setOpenSnackBarAllSource] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(
    selectedAccountInitialData,
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: addChannelFormikInitialValues,
    validationSchema: addChannelFormikValidationSchema(intl),
    onSubmit: ({ IS_PRIVATE_CHANNEL }) => {
      handleAddChannel(IS_PRIVATE_CHANNEL);
    },
  });

  const isPrivateChannel = formik.values.IS_PRIVATE_CHANNEL;
  const redirectionDone = localStorage.getItem("redirection_done");
  const activeCategory =
    selectedCategoryStats?.categoryName ||
    localStorage.getItem("active_category") ||
    "";

  const newSteps =
    activeCategory == "Social Media" && activeStep !== 0
      ? addChannelSocialSteps
      : addChannelInitialSteps;

  const handleStep = (step, categoryStatus) => {
    if (step === 2 || step === 3) {
      return getDataSourcesForCategory(step);
    }
    if (step === 0) {
      localStorage.removeItem("active_datasource");
      setIsEmptyAccountScreen(false);
      formik.resetForm();
      setMonitorData(defaultMonitorData);
      setMonitorErrors(defaultMonitorError);
      setStats({
        ...stats,
        activeDataSourceName: "",
      });
      setActiveStep(0);
      if (
        !socialMediaDataSource ||
        selectedAccount?.attributes?.used_in_private_channel !== 0
      ) {
        formik.setFieldValue("IS_PRIVATE_CHANNEL", false);
      }
    } else {
      if (categoryStatus === false) {
        setIsUpgradeModalOpen(true);
      } else if (activeCategory === "Call Center") {
        setIsIntegrationModalOpen(true);
      } else {
        getDataSourcesForCategory(step);
      }
    }
    localStorage.removeItem("redirection_done");
    setOpenSnackBar(false);
    localStorage.removeItem("auth_fail");
  };

  const getDataSourcesForCategory = (step) => {
    resetCategoryDataSource();
    getCategoryDataSource(activeCategory, {
      onSuccess: (data) => {
        if (data?.data?.length !== 0) {
          for (var i = 0; i < data.data.length; i++) {
            if (
              data.data[i].active === true &&
              (stats.activeDataSourceName == data.data[i].name ||
                stats.activeDataSourceName.length == 0)
            ) {
              getAuthorizedAccounts(data.data[i].id, step, data.data[i].name);
              break;
            }
          }
        } else {
          setIsEmptyAccountScreen(true);
        }
      },
    });
  };

  const getAuthorizedAccounts = (
    activeDataSourceId,
    step,
    activeDataSourceName,
  ) => {
    resetAuthorizedAccounts();
    getAuthorizedAccountsMutation(activeDataSourceId, {
      onSuccess: ({ data }) => {
        const isEmptyData = data?.length === 0;
        setIsEmptyAccountScreen(isEmptyData);
        setMonitorData(defaultMonitorData);
        setMonitorErrors(defaultMonitorError);
        setStats((prev) => ({
          ...prev,
          activeDataSourceId,
          activeDataSourceName,
        }));
        setActiveStep(parseInt(step));
        setOpenSnackBar(true);
        setSocialMediaDataSource(
          addChannelSocialAccounts.includes(activeDataSourceName),
        );
      },
    });
  };

  const handleChooseCategory = (categoryName, categoryStatus) => {
    setSelectedCategoryStats({
      categoryName,
      categoryStatus,
    });
  };

  const handleCloseModal = () => {
    setIsIntegrationModalOpen(false);
    setIsUpgradeModalOpen(false);
  };

  const handleAddChannel = (privateChannel) => {
    const { activeDataSourceName, activeDataSourceId } = stats;
    authorizedMonitorType.forEach((monitorType) => {
      if (monitorType?.attributes.data_source_id === activeDataSourceId) {
        const monitorModel = createMonitorModel(selectedAccount, monitorType);
        const monitorOptions = createMonitorOptions(
          monitorType,
          privateChannel,
          activeDataSourceName,
          selectedAccount,
          socialMediaDataSource,
          formik,
          monitorData,
        );
        const dataSources = [activeDataSourceId];
        const isWhatsAppChannel = monitorType?.attributes?.data_source_id === 9; //static id for whatsapp
        if (
          (isWhatsAppChannel &&
            monitorType?.attributes.monitor_type_name === "MANAGED_ACCOUNT") ||
          !isWhatsAppChannel
        ) {
          createMonitor(
            {
              monitor_model: monitorModel,
              monitor_options: monitorOptions,
              data_sources: dataSources,
              activeDataSourceNameOrCustomerCareVal: [
                "TWITTER",
                "FACEBOOK",
                "INSTAGRAM",
              ].includes(activeDataSourceName)
                ? [monitorData?.customerCareVal]
                : undefined,
            },
            {
              onSuccess: (res) => {
                const onSuccess = () => {
                  dispatch(setChannelAdded(true));
                  history.push("/manage-channels/list");
                };
                const onError = (errorMessage) => {
                  dispatch(setChannelFailed(errorMessage));
                  history.push("/manage-channels/list");
                };
                handleSuccessErrorCreateChannel(res, onSuccess, onError);
              },
            },
          );
        }
      }
    });
    localStorage.removeItem("redirection_done");
  };
  //genesys auth
  const url = window.location?.search;
  const code = url?.slice(url?.indexOf("?code=") + 6);
  // In case of redirection after Account Authentication
  useEffect(() => {
    if (
      redirectionDone === "true" &&
      !isPrivateChannel &&
      !url?.includes("code")
    ) {
      getDataSourcesForCategory(1);
    }
    if (window.location.search.includes("auth=failed")) {
      localStorage.setItem("auth_fail", "true");
      window.history.pushState({}, null, window.location.href.split("?")[0]);
    }
  }, []);

  useEffect(() => {
    if (isDataSourceError) {
      setFailSnackBarData({
        message: "error_request_message",
        severity: "error",
        title: "error_request_title",
      });
      setOpenSnackBarAllSource(true);
      window.history.pushState({}, "", window.location.pathname);
    }
  }, [isDataSourceError]);

  useEffect(() => {
    if (
      code !== undefined &&
      code !== "" &&
      genesysDataSourceId &&
      url?.includes("code") &&
      !url?.includes("error")
    ) {
      genesysLastStepAuth(
        genesysDataSourceId,
        code,
        setGenesysCodeLoading,
        setGenesysStatus,
        setFailSnackBarData,
        setOpenSnackBarAllSource,
        getDataSourcesForCategory,
      );
    }
  }, [code, genesysDataSourceId]);

  //check if authentication failed or not
  const isAuthFailed = localStorage.getItem("auth_fail");
  const activeDatasourcesName =
    stats?.activeDataSourceName === "GOOGLEMYBUSINESS" ||
    stats?.activeDataSourceName === "GMAIL" ||
    stats?.activeDataSourceName === "GENESYS";

  useEffect(() => {
    let pathname = "/manage-channels/add-channel";
    if ((activeStep == 0 && activeCategory?.length > 0) || activeStep == 1) {
      history.push({
        pathname: `/manage-channels/add-channel/${activeCategory == "Social Media" ? "Social" : activeCategory}`,
      });
    } else if (activeStep == 2 && isPrivateChannel) {
      history.push({
        pathname: "/manage-channels/add-channel/bot-config",
      });
    } else if (activeStep == 3 && isPrivateChannel) {
      history.push({
        pathname: "/manage-channels/add-channel/session-config",
      });
    }
    history.push({ pathname });
  }, [activeStep, activeCategory]);

  const handleIntegrationOrUpgradeStatus = (status) => {
    setSocialListeningSuccess(status);
  };
  const isLoading =
    isAuthorizedAccountsLoading || isDataSourceForCategoryLoading;

  const isAnyLoading =
    isDataSourceForCategoryLoading || isAuthorizedAccountsLoading;

  const isBotConfigurationShown =
    activeStep === 2 &&
    isPrivateChannel &&
    socialMediaDataSource &&
    activeStep !== 0;

  const isSessionConfigurationShown =
    activeStep === 3 && isPrivateChannel && socialMediaDataSource;

  const isAddChannelButtonShown =
    (activeStep === 3 && socialMediaDataSource) ||
    (activeStep === 1 && !socialMediaDataSource);
  return (
    <Container fixed className="add-channel">
      {activeStep === 1 && activeDatasourcesName && isAuthFailed == "true" && (
        <Box className="error-msg-auth">
          <Snackbar
            open={openSnackBar}
            severity={"error"}
            message={CheckValueLocale("add_channel_auth_info", "", {}, intl)}
            title={CheckValueLocale("failed_error", "", {}, intl)}
            handleClose={() => setOpenSnackBar(false)}
          />
        </Box>
      )}
      {redirectionDone === "true" &&
      (isLoading ||
        isAuthorizedAccountsLoading ||
        (genesysCodeLoading &&
          url?.includes("code") &&
          !url?.includes("error"))) ? (
        <Box sx={{ margin: "auto" }}>
          <CircularLoading />
        </Box>
      ) : (
        <>
          <AddChannelPageHeader />
          <GoogleEmailPolicyAlert
            activeDatasourcesName={activeDatasourcesName}
            activeStep={activeStep}
            stats={stats}
          />
          <AddChannelCustomStepper
            activeStep={activeStep}
            isPrivateChannel={isPrivateChannel}
            newSteps={newSteps}
            selectedAccount={selectedAccount}
            socialMediaDataSource={socialMediaDataSource}
          />

          {activeStep === 0 ? (
            <ChooseCategoryStep
              companyProducts={companyDataCategoriesData || []}
              handleChooseCategory={handleChooseCategory}
              activeProductName={activeCategory}
            />
          ) : activeStep === 1 ? (
            <SetUpChannelStep
              formik={formik}
              activeCategoryName={activeCategory}
              categotyDataSources={categoryDataSources || []}
              authorizedAccounts={authorizedAccounts || []}
              includedAccounts={
                authorizedAccountsResponse?.data?.included || []
              }
              showEmptyAccountScreen={isEmptyAccountScreen}
              activeDataSourceId={stats.activeDataSourceId}
              activeDataSourceName={stats.activeDataSourceName}
              getAuthorizedAccounts={getAuthorizedAccounts}
              authorizedAccountsLoading={isAuthorizedAccountsLoading}
              authorizedMonitorType={authorizedMonitorType}
              setGenesysCodeLoading={setGenesysCodeLoading}
              genesysDataSourceId={genesysDataSourceId}
              genesysStatus={genesysStatus}
              code={code}
              failSnackBarData={failSnackBarData}
              openSnackBarAllSource={openSnackBarAllSource}
              setOpenSnackBarAllSource={setOpenSnackBarAllSource}
              setFailSnackBarData={setFailSnackBarData}
              setSelectedAcount={setSelectedAccount}
              selectedAcount={selectedAccount}
              tiktokDataSourceId={tiktokDataSourceId}
              monitorData={monitorData}
              setMonitorData={setMonitorData}
              monitorErrors={monitorErrors}
              setMonitorErrors={setMonitorErrors}
            />
          ) : isBotConfigurationShown ? (
            <BotConfiguration formik={formik} />
          ) : isSessionConfigurationShown ? (
            <SessionConfiguration
              formik={formik}
              loading={isCreateMonitorLoading}
            />
          ) : null}
          <AddChannelFooter
            activeCategory={activeCategory}
            activeStep={activeStep}
            addChannelBtn={isCreateMonitorLoading}
            formik={formik}
            handleAddChannel={handleAddChannel}
            handleStep={handleStep}
            isAddChannelButtonShown={isAddChannelButtonShown}
            isAnyLoading={isAnyLoading}
            isEmptyAccountScreen={isEmptyAccountScreen}
            isPrivateChannel={isPrivateChannel}
            selectedAccount={selectedAccount}
            selectedCategoryStats={selectedCategoryStats}
            setGenesysStatus={setGenesysStatus}
            socialMediaDataSource={socialMediaDataSource}
            monitorErrors={monitorErrors}
          />
          <AddChannelUpgradeIntegrationPopup
            title={CheckValueLocale("request_upgrade", "", {}, intl)}
            body={intl.formatMessage(
              { id: "upgrade_modal_body" },
              {
                activeCategoryName: CheckValueLocale(
                  activeCategory,
                  "",
                  {},
                  intl,
                ),
              },
            )}
            rightBtn={CheckValueLocale("request_an_upgrade", "", {}, intl)}
            open={isUpgradeModalOpen}
            activeCategory={activeCategory}
            close={handleCloseModal}
            onSuccess={() => handleIntegrationOrUpgradeStatus(true)}
            onError={() => handleIntegrationOrUpgradeStatus(false)}
          />
          <AddChannelUpgradeIntegrationPopup
            activeCategory={activeCategory}
            title={CheckValueLocale("request_integration", "", {}, intl)}
            body={CheckValueLocale("integration_modal_body", "", {}, intl)}
            close={handleCloseModal}
            onError={() => handleIntegrationOrUpgradeStatus(false)}
            onSuccess={() => handleIntegrationOrUpgradeStatus(true)}
            open={isIntegrationModalOpen}
            rightBtn={CheckValueLocale("send_request", "", {}, intl)}
          />
          <AddChannelSocialListingSnackbar
            setSocialListeningSuccess={setSocialListeningSuccess}
            socialListeningSuccess={socialListeningSuccess}
          />
        </>
      )}
    </Container>
  );
};
export default AddChannel;
