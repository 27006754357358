import React, { useEffect } from "react";
import {
  Box,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { checkKeyboardLanguage, CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import UploadLogo from "./uploadLogo";
import "../../surveyBuilder.scss";

const ConfigsTab = (props) => {
  const {
    surveyData,
    setSurveyData,
    setLogoData,
    logoData,
    handleSaveChanges,
    setTab,
    multiRes,
    handleMultiRes,
    setSaveChangesFlag,
  } = props;
  const intl = useIntl();

  useEffect(() => {
    setTab("configs");
  }, []);

  const handleChangeText = (e, type) => {
    if (type === "title")
      return setSurveyData({ ...surveyData, title: e.target.value });
    if (type === "subtitle")
      return setSurveyData({ ...surveyData, subtitle: e.target.value });
  };

  return (
    <>
      <Box className="sec-title">
        <Typography component="h1">
          {CheckValueLocale("survey_customize", "", {}, intl)}
        </Typography>
      </Box>
      <Box className="info-header">
        <UploadLogo
          setLogoData={setLogoData}
          logoData={logoData}
          handleSaveChanges={handleSaveChanges}
          surveyData={surveyData}
          setSaveChangesFlag={setSaveChangesFlag}
        />
      </Box>
      <Box className="information-box" data-testid="title_field">
        <TextField
          name="title"
          variant="outlined"
          className="info-field"
          value={surveyData?.title}
          onChange={(e) => handleChangeText(e, "title")}
          placeholder={CheckValueLocale("Untitled Survey", "", {}, intl)}
          style={{
            direction: checkKeyboardLanguage(surveyData?.title) ? "rtl" : "ltr",
          }}
        />
        <Box className={"info-text"}>
          {CheckValueLocale("this_title_on_top_of_survey", "", {}, intl)}
        </Box>
      </Box>

      <Box className="information-box" data-testid="subtitle_field">
        <TextField
          name="subtitle"
          variant="outlined"
          className="info-field"
          value={surveyData?.subtitle}
          onChange={(e) => handleChangeText(e, "subtitle")}
          placeholder={CheckValueLocale(
            "Survey Description (Optional)",
            "",
            {},
            intl,
          )}
          style={{
            direction: checkKeyboardLanguage(surveyData?.subtitle)
              ? "rtl"
              : "ltr",
          }}
        />
        <Box className={"info-text"}>
          {CheckValueLocale("this_subtitle_below_title", "", {}, intl)}
        </Box>
      </Box>
      <Box mx={2}>
        <Divider />
      </Box>
      <Box>
        <Box className="sec-title">
          <Typography component="h1">
            {CheckValueLocale("survey_access", "", {}, intl)}
          </Typography>
        </Box>
        <Box className="multiple-responses-container">
          <FormGroup>
            <FormControlLabel
              className="multiple-responses"
              control={<Switch checked={multiRes} onChange={handleMultiRes} />}
              label={CheckValueLocale(
                "multiple_responses_per_device",
                "",
                {},
                intl,
              )}
            />
          </FormGroup>
        </Box>
      </Box>
    </>
  );
};

export default ConfigsTab;
