import { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import {
  CheckValueLocale,
  commasAfterDigit,
  handleUnixFormat,
  translateLocalsFromRabbitMQ,
} from "utils/helpers";
import xPlatform from "images/social-icons/x-platform-icon.svg";
import xPlatformWhite from "images/social-icons/x-platform-white-icon.svg";
import PieChartWidget from "components/widgets/pieChartWidget";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import LineChartWidget from "components/widgets/lineChartWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget";
import BarChartWidget from "components/widgets/barChartWidget";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";

import { faPercent, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/pro-regular-svg-icons";
import CommonFunctions from "pages/Monitors/Components/commonFunctions";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import MediaTw from "components/media/media-tw/mediaTw";
import QuestionCard from "pages/Monitors/Components/questionCard";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import ThemeSection from "pages/Monitors/Components/themeSection";
import PunchCardWidget from "components/widgets/punchCardWidget";
import DragHandleIcon from "../dragHandleIcon";
import _ from "lodash";

const Container = SortableContainer(({ children }) => {
  return (
    <Grid container spacing={2} className="live-dashboard-widget-container">
      {children}
    </Grid>
  );
});

const DragHandle = SortableHandle(() => <DragHandleIcon />);

const Element = SortableElement(
  ({ widgetItem, showEditBtn, widgetSection }) => {
    const intl = useIntl();
    if (widgetItem?.typeWidget === "pieChart") {
      return (
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className="dashboard-widget-wrapper-column"
        >
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <PieChartWidget
              title={widgetItem?.title}
              titleToolTip={widgetItem?.titleToolTip}
              data={widgetItem?.data}
              subData={widgetItem?.subData}
              color={widgetItem?.color}
              areaStyle={widgetItem?.areaStyle}
              toolTipLabel={widgetItem?.toolTipLabel}
              showDownloadIcon={widgetItem?.showDownloadIcon}
              showAI={widgetItem?.showAI}
              twitterIcon={widgetItem?.twitterIcon}
              pieChartPreLoaderDataSources={
                widgetItem?.pieChartPreLoaderDataSources
              }
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              iconAfterTitle={widgetItem?.iconAfterTitle}
              inspectorName={widgetItem?.inspectorName}
              handleOpenQuickInspect={widgetItem?.handleOpenQuickInspect}
              liveDashboardWidget
              widgetSection={widgetSection}
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "lineChart") {
      return (
        <Grid item lg={6} xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <LineChartWidget
              title={widgetItem?.title}
              titleToolTip={widgetItem?.titleToolTip}
              totalLabel={widgetItem?.totalLabel}
              totalValue={widgetItem?.totalValue}
              data={widgetItem?.data}
              showGroupBy={widgetItem?.showGroupBy}
              toolTipLabel={widgetItem?.toolTipLabel}
              labelColor={widgetItem?.labelColor}
              showDownloadIcon={widgetItem?.showDownloadIcon}
              bigImg={widgetItem?.bigImg}
              lineChartPreLoaderDataSources={
                widgetItem?.lineChartPreLoaderDataSources
              }
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              iconAfterTitle={widgetItem?.iconAfterTitle}
              handleOpenQuickInspect={widgetItem?.handleOpenQuickInspect}
              inspectorName={widgetItem?.inspectorName}
              haveData={widgetItem?.haveData}
              liveDashboardWidget
              widgetSection={widgetSection}
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "stackedLineChart") {
      return (
        <Grid item lg={6} xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <StackedLineWidget
              title={widgetItem?.title}
              titleToolTip={widgetItem?.titleToolTip}
              data={widgetItem?.data}
              showGroupBy={widgetItem?.showGroupBy}
              showScales={widgetItem?.showScales}
              areaStyle={widgetItem?.areaStyle}
              toolTipLabel={widgetItem?.toolTipLabel}
              showAI={widgetItem?.showAI}
              showDownloadIcon={widgetItem?.showDownloadIcon}
              bigImg={widgetItem?.bigImg}
              handleOpenQuickInspect={widgetItem?.handleOpenQuickInspect}
              inspectorName={widgetItem?.inspectorName}
              dataPiechart={widgetItem?.dataPiechart}
              hideLegend={widgetItem?.hideLegend}
              labelColors={widgetItem?.labelColors}
              isSwitchCase={widgetItem?.isSwitchCase}
              isSentimentAnalysisWidget={widgetItem?.isSentimentAnalysisWidget}
              isSentimentAnalysisShowCharts={
                widgetItem?.isSentimentAnalysisShowCharts
              }
              stackedLineWidgetPreLoaderDataSources={
                widgetItem?.stackedLineWidgetPreLoaderDataSources
              }
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              iconAfterTitle={widgetItem?.iconAfterTitle}
              liveDashboardWidget
              widgetSection={widgetSection}
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "stackedBarChart") {
      return (
        <Grid item lg={6} xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <StackedBarWidget
              title={widgetItem?.title}
              titleToolTip={widgetItem?.titleToolTip}
              showDownloadIcon={widgetItem?.showDownloadIcon}
              bigImg={widgetItem?.bigImg}
              posts={widgetItem?.posts}
              colors={widgetItem?.colors}
              labels={widgetItem?.labels}
              showGroupBy={widgetItem?.showGroupBy}
              showScales={widgetItem?.showScales}
              showZoomPercentage={widgetItem?.showZoomPercentage}
              postsColumnPreLoaderDataSources={
                widgetItem?.postsColumnPreLoaderDataSources
              }
              hideLegend={widgetItem?.hideLegend}
              dataPiechart={widgetItem?.dataPiechart}
              showChartDetailsWidget={widgetItem?.showChartDetailsWidget}
              statsDetailsData={widgetItem?.statsDetailsData}
              isInteractionStatsWrapperStyle={
                widgetItem?.isInteractionStatsWrapperStyle
              }
              reachFunnel={widgetItem?.reachFunnel}
              iconAfterTitle={widgetItem?.iconAfterTitle}
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              showPostsInteractionStats={widgetItem?.showPostsInteractionStats}
              reachFunnelAccount={widgetItem?.reachFunnelAccount}
              liveDashboardWidget
              widgetSection={widgetSection}
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "tableWidget") {
      return (
        <Grid item lg={6} xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <TableWidget
              unixDate={widgetItem?.unixDate}
              showProfile={widgetItem?.showProfile}
              productId={widgetItem?.productId}
              monitorId={widgetItem?.monitorId}
              isXPlatform={widgetItem?.isXPlatform}
              secondaryTitle={widgetItem?.secondaryTitle}
              circularPP={widgetItem?.circularPP}
              itemsList={widgetItem?.itemsList}
              title={widgetItem?.title}
              titleToolTip={widgetItem?.titleToolTip}
              data={widgetItem?.data}
              leftColomnTitle={widgetItem?.leftColomnTitle}
              rightColomnTitle={widgetItem?.rightColomnTitle}
              tableChartPreLoaderDataSources={
                widgetItem?.tableChartPreLoaderDataSources
              }
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              twitterIcon={widgetItem?.twitterIcon}
              tablePagination={widgetItem?.tablePagination}
              count={widgetItem?.count}
              iconAfterTitle={widgetItem?.iconAfterTitle}
              wordsList={widgetItem?.wordsList}
              keywords={widgetItem?.keywords}
              handleOpenQuickInspect={widgetItem?.handleOpenQuickInspect}
              inspectorName={widgetItem?.inspectorName}
              dynamicClass={widgetItem?.dynamicClass}
              hashtags={widgetItem?.hashtags}
              widgetSection={widgetSection}
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "mediaTw") {
      return (
        <Grid item xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <MediaTw
              title={widgetItem?.title}
              titleToolTip={widgetItem?.titleToolTip}
              data={widgetItem?.data}
              errorMessage={widgetItem?.errorMessage}
              iconAfterTitle={widgetItem?.iconAfterTitle}
              mediaTwPreLoaderDataSources={
                widgetItem?.mediaTwPreLoaderDataSources
              }
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              liveDashboardWidget
              widgetSection={widgetSection}
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "questionCard") {
      return (
        <Grid item xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <Box component="p" className="question-card-title">
              <Box component="span" className="widget-title">
                {CheckValueLocale(widgetItem?.questionCardTitle, "", {}, intl)}
                {widgetSection ? (
                  <span className="widget-title-section">
                    ({CheckValueLocale(widgetSection, "", {}, intl)})
                  </span>
                ) : (
                  ""
                )}
              </Box>
              {widgetItem?.questionCardIcon}
            </Box>
            <QuestionCard
              unixDate={widgetItem?.unixDate}
              showProfile={widgetItem?.showProfile}
              productId={widgetItem?.productId}
              latestUnanswer={widgetItem?.latestUnanswer}
              icon={widgetItem?.icon}
              iconColor={widgetItem?.iconColor}
              showBanIcon={widgetItem?.showBanIcon}
              latestUnanswerPreloader={widgetItem?.latestUnanswerPreloader}
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              updateAnalysis={widgetItem?.updateAnalysis}
              noOfChar={widgetItem?.noOfChar}
              singleTweetAnalysis={widgetItem?.singleTweetAnalysis}
              hideWhenRetweeted={widgetItem?.hideWhenRetweeted}
              showRetweet={widgetItem?.showRetweet}
              showHeart={widgetItem?.showHeart}
              showScreenName={widgetItem?.showScreenName}
              showDefaultImg={widgetItem?.showDefaultImg}
              className={widgetItem?.className}
              datasourcePostsType="TWITTER"
              isTwitterLiveDashBoard
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "themeSection") {
      return (
        <Grid item xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <ThemeSection
              iconAfterTitle={widgetItem?.iconAfterTitle}
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              eventName={widgetItem?.eventName}
              resetAllFields={widgetItem?.resetAllFields}
              response={widgetItem?.response}
              themeTrendPreLoader={widgetItem?.themeTrendPreLoader}
              setThemeTrendPreLoader={widgetItem?.setThemeTrendPreLoader}
              themeAnalysisPreLoader={widgetItem?.themeAnalysisPreLoader}
              setThemeAnalysisPreLoader={widgetItem?.setThemeAnalysisPreLoader}
              liveDashboardWidget
              widgetSection={widgetSection}
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "barChart") {
      return (
        <Grid item lg={6} xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <div className="chart-body">
              <BarChartWidget
                title={widgetItem?.title}
                titleToolTip={widgetItem?.titleToolTip}
                data={widgetItem?.data}
                showAI={widgetItem?.showAI}
                showDownloadIcon={widgetItem?.showDownloadIcon}
                showChartStatsWidget={widgetItem?.showChartStatsWidget}
                showZoomPercentage={widgetItem?.showZoomPercentage}
                customerCareChart={widgetItem?.customerCareChart}
                gridHeight={widgetItem?.gridHeight}
                respTimeDistributionTooltip={
                  widgetItem?.respTimeDistributionTooltip
                }
                statsData={widgetItem?.statsData}
                dataColor={widgetItem?.dataColor}
                toolTipLabel={widgetItem?.toolTipLabel}
                isTwitterAccount={widgetItem?.isTwitterAccount}
                iconAfterTitle={widgetItem?.iconAfterTitle}
                barChartPreLoaderDataSources={
                  widgetItem?.barChartPreLoaderDataSources
                }
                preLoaderTrackerDataSources={
                  widgetItem?.preLoaderTrackerDataSources
                }
                bigImg={widgetItem?.bigImg}
                showFollowersGrowthStats={widgetItem?.showFollowersGrowthStats}
                showGroupBy={widgetItem?.showGroupBy}
                showChartDetailsWidget={widgetItem?.showChartDetailsWidget}
                statsDetailsData={widgetItem?.statsDetailsData}
                liveDashboardWidget
                widgetSection={widgetSection}
              />
            </div>
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "punchCard") {
      return (
        <Grid item lg={6} xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <PunchCardWidget
              title={widgetItem?.title}
              titleToolTip={widgetItem?.titleToolTip}
              clientActivity={widgetItem?.clientActivity}
              minValueClient={widgetItem?.minValueClient}
              maxValueClient={widgetItem?.maxValueClient}
              showDownloadIcon={widgetItem?.showDownloadIcon}
              bigImg={widgetItem?.bigImg}
              iconAfterTitle={widgetItem?.iconAfterTitle}
              authorActivityPreLoaderDataSources={
                widgetItem?.authorActivityPreLoaderDataSources
              }
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              liveDashboardWidget
              widgetSection={widgetSection}
            />
          </Box>
        </Grid>
      );
    } else {
      return "";
    }
  },
);

const DataSourceContent = ({
  resetAllFields,
  response,
  preLoaderTrackerDataSources,
  unixDate,
  singleTweetAnalysis,
  xPlateformWidgetTypes,
  setXPlateformWidgetTypes,
  sortWidgets,
  getNameKeyWidget,
  showEditBtn,
  setTotalStatisticWidgets,
  setLoadingStatisticWidgets,
}) => {
  const intl = useIntl();
  const urlParams = useParams();
  const monitorId = urlParams.monitorId;
  const [contentStyles, setContentStyles] = useState([]);
  const [contentStylesPreLoader, setContentStylesPreLoader] = useState([]);
  const [associatedTopics, setAssociatedTopics] = useState([]);
  const [associatedTopicsPreLoader, setAssociatedTopicsPreLoader] = useState(
    [],
  );
  const [topLanguages, setTopLanguages] = useState([]);
  const [topLanguagesPreLoader, setTopLanguagesPreLoader] = useState([]);
  const [dialects, setDialects] = useState([]);
  const [subDialects, setSubDialects] = useState([]);
  const [dialectsPreLoader, setDialectsPreLoader] = useState([]);
  const [topCities, setTopCities] = useState([]);
  const [topCitiesPreLoader, setTopCitiesPreLoader] = useState([]);
  const [topCountries, setTopCountries] = useState([]);
  const [topCountriesPreLoader, setTopCountriesPreLoader] = useState([]);
  const [genderDistribution, setGenderDistribution] = useState([]);
  const [genderDistributionPreLoader, setGenderDistributionPreLoader] =
    useState([]);
  const [accountTypes, setAccountTypes] = useState([]);
  const [accountTypesPreLoader, setAccountTypesPreLoader] = useState([]);
  const [topSources, setTopSources] = useState([]);
  const [topSourcesPreLoader, setTopSourcesPreLoader] = useState([]);
  const [dataToSend, setDataToSend] = useState({
    languages: [],
    countries: [],
    cities: [],
    sources: [],
  });
  const [commentsVolume, setCommentsVolume] = useState([]);
  const [totalCommentsVolumeCount, setTotalCommentsVolumeCount] = useState([]);
  const [commentsVolumePreLoader, setCommentsVolumePreLoader] = useState([]);
  const [commentsInteractions, setCommentsInteractions] = useState([]);
  const [commentsInteractionsPreLoader, setCommentsInteractionsPreLoader] =
    useState([]);
  const [commentsInteractionsLegend, setCommentsInteractionsLegend] = useState(
    [],
  );
  const [totalValuePostsInteractions, setTotalValuePostsInteractions] =
    useState(0);
  const [maxValuePostsInteractions, setMaxValuePostsInteractions] = useState(0);
  const [maxValueInteractionsDate, setMaxValueInteractionsDate] = useState(0);
  const [avgValuePostsInteractions, setAvgValuePostsInteractions] = useState(0);
  const [reachFunnel, setReachFunnel] = useState([]);
  const [reachFunnelPreLoaderDataSources, setReachFunnelPreLoaderDataSources] =
    useState([]);
  const [sentimentAnalysis, setSentimentAnalysis] = useState([]);
  const [sentimentAnalysisPreLoader, setSentimentAnalysisPreLoader] = useState(
    [],
  );
  const [sentimentPieChart, setSentimentPieChart] = useState([]);
  const [negativeArray, setNegativeArray] = useState([]);
  const [positiveArray, setPositiveArray] = useState([]);
  const [neutralArray, setNeutralArray] = useState([]);
  const [topPosts, setTopPosts] = useState([]);
  const [topPostsCount, setTopPostsCount] = useState(0);
  const [topTopPostsPreLoaderDataSources, setTopPostsPreLoaderDataSources] =
    useState([]);
  const [topVideos, setTopVideos] = useState([]);
  const [topVideosPreLoader, setTopVideosPreLoader] = useState([]);
  const [topImages, setTopImages] = useState([]);
  const [topImagesPreLoader, setTopImagesPreLoader] = useState([]);
  const [topKeywords, setTopKeywords] = useState([]);
  const [topKeywordsPreLoader, setTopKeywordsPreLoader] = useState([]);
  const [topHashtags, setTopHashtags] = useState([]);
  const [topHashtagsPreLoader, setTopHashtagsPreLoader] = useState([]);
  const [topVerifiedEngagers, setTopVerifiedEngagers] = useState([]);
  const [topVerifiedEngagersPreLoader, setTopVerifiedEngagersPreLoader] =
    useState([]);
  const [topEngagers, setTopEngagers] = useState([]);
  const [topEngagersPreLoader, setTopEngagersPreLoader] = useState([]);
  const [topInfleuncers, setTopInfleuncers] = useState([]);
  const [topInfleuncersPreLoader, setTopInfleuncersPreLoader] = useState([]);
  const [themeTrendPreLoader, setThemeTrendPreLoader] = useState([]);
  const [themeAnalysisPreLoader, setThemeAnalysisPreLoader] = useState([]);

  // posts section
  const [cardData, setCardData] = useState([]);
  const [postsPreloader, setPostsPreloader] = useState([]);

  // questions section
  const [latestUnansweredQuestions, setLatestUnansweredQuestions] = useState(
    [],
  );
  const [
    latestUnansweredQuestionsPreloader,
    setLatestUnansweredQuestionsPreloader,
  ] = useState([]);

  // comments mentions section
  const [commentsMentions, setCommentsMentions] = useState([]);
  const [commentsMentionsPreloader, setCommentsMentionsPreloader] = useState(
    [],
  );

  // customer care section
  const [latestUnanswer, setLatestUnanswer] = useState([]);
  const [customerCarePerformance, setCustomercarePerformance] = useState({});
  const [latestUnanswerPreloader, setLatestUnanswerPreloader] = useState([]);
  const [customerCarePreloader, setCustomercarePreloader] = useState([]);
  const [unAnsweredQuestion, setUnanswerQuestion] = useState(0);
  const [respRate, setRespRate] = useState(0);
  const [totalQuestion, setTotalQuestion] = useState(0);
  const [customerCareData, setcustomerCareData] = useState([]);

  // account section
  const [postsVolume, setPostsVolume] = useState([]);
  const [totalPostsVolumeCount, setTotalPostsVolumeCount] = useState([]);
  const [topVideosAccount, setTopVideosAccount] = useState([]);
  const [topImagesAccount, setTopImagesAccount] = useState([]);
  const [topPostsAccount, setTopPostsAccount] = useState([]);
  const [topPostsAccountCount, setTopPostsAccountCount] = useState(0);
  const [authorActivity, setAuthorActivity] = useState([]);
  const [minValueAuthorActivity, setMinValueAuthorActivity] = useState(0);
  const [maxValueAuthorActivity, setMaxValueAuthorActivity] = useState(0);
  const [postsContentType, setPostsContentType] = useState([]);
  const [contentTypeLegend, setContentTypeLegend] = useState([]);

  const [reachFunnelAccount, setReachFunnelAccount] = useState([]);

  const [postsInteractions, setPostsInteractions] = useState([]);
  const [
    maxValuePostsInteractionsAccount,
    setMaxValuePostsInteractionsAccount,
  ] = useState(0);
  const [minValuePostsInteractions, setMinValuePostsInteractions] = useState(0);
  const [
    avgValuePostsInteractionsAccount,
    setAvgValuePostsInteractionsAccount,
  ] = useState(0);
  const [maxValueInteractionsDateAccount, setMaxValueInteractionsDateAccount] =
    useState(0);
  const [minValueInteractionsDate, setMinValueInteractionsDate] = useState(0);
  const [postInteractionsLegend, setPostInteractionsLegend] = useState([]);

  const [followersGrowth, setFollowersGrowth] = useState([]);
  const [totalFollowersFollowersGrowth, setTotalFollowersGrowth] = useState(0);
  const [maxChangeFollowersGrowth, setMaxChangeFollowersGrowth] = useState(0);
  const [avgChangeFollowersGrowth, setAvgChangeFollowersGrowth] = useState(0);

  // Values of DataSources totrack each widget preloader
  const [postsVolumePreLoaderDataSources, setPostsVolumePreLoaderDataSources] =
    useState([]);
  const [
    topVideosAccountPreLoaderDataSources,
    setTopVideosAccountPreLoaderDataSources,
  ] = useState([]);
  const [
    topImagesAccountPreLoaderDataSources,
    setTopImagesAccountPreLoaderDataSources,
  ] = useState([]);
  const [
    topTopPostsAccountPreLoaderDataSources,
    setTopPostsAccountPreLoaderDataSources,
  ] = useState([]);
  const [
    authorActivityPreLoaderDataSources,
    setAuthorActivityPreLoaderDataSources,
  ] = useState([]);
  const [
    postsInteractionsPreLoaderDataSources,
    setPostsInteractionsPreLoaderDataSources,
  ] = useState([]);
  const [
    postsContentTypePreLoaderDataSources,
    setPostsContentTypePreLoaderDataSources,
  ] = useState([]);
  const [
    followersGrowthPreLoaderDataSources,
    setFollowersGrowthPreLoaderDataSources,
  ] = useState([]);
  const [
    reachFunnelAccountPreLoaderDataSources,
    setReachFunnelAccountPreLoaderDataSources,
  ] = useState([]);

  const { updateAnalysis } = CommonFunctions();

  let interactionsStats = [
    {
      name: "total_interactions",
      value: totalValuePostsInteractions,
    },
    {
      name: "max_interaction",
      value: maxValuePostsInteractions,
      date: maxValueInteractionsDate,
    },
    {
      name: "avg_interaction_per_day",
      value: avgValuePostsInteractions,
    },
  ];

  const twitterIcon = (
    <span className="topics-twitter-icon">
      <img
        src={xPlatform}
        alt="x-platform-logo"
        className={"icon-after-title twitter-icon"}
      />
    </span>
  );

  const xPlateformWidgetObj = {
    Twitter__EngagementsPage__content_style: {
      typeWidget: "pieChart",
      title: getNameKeyWidget("Twitter__EngagementsPage__content_style"),
      titleToolTip: "comments_content_style_tw_tooltip",
      showDownloadIcon: true,
      color: ["#ED6C02", "#2AAAE2", "#80868C", "#8CC63F"],
      data: contentStyles,
      pieChartPreLoaderDataSources: contentStylesPreLoader,
      showAI: true,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      twitterIcon: twitterIcon,
      iconAfterTitle: twitterIcon,
    },
    Twitter__EngagementsPage__associated_topics: {
      typeWidget: "pieChart",
      title: getNameKeyWidget("Twitter__EngagementsPage__associated_topics"),
      titleToolTip: "posts_associated_topics_ig_tooltip",
      data: associatedTopics,
      areaStyle: true,
      toolTipLabel: "toolTipLabel",
      showDownloadIcon: true,
      showAI: true,
      pieChartPreLoaderDataSources: associatedTopicsPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      twitterIcon: twitterIcon,
      iconAfterTitle: twitterIcon,
    },
    Twitter__EngagementsPage__top_languages: {
      typeWidget: "pieChart",
      title: getNameKeyWidget("Twitter__EngagementsPage__top_languages"),
      titleToolTip: "top_languages_fb_tooltip",
      data: topLanguages,
      areaStyle: true,
      toolTipLabel: "toolTipLabel",
      showDownloadIcon: true,
      showAI: true,
      pieChartPreLoaderDataSources: topLanguagesPreLoader,
      // inspectorName: "languages",
      inspectorName: "",
      handleOpenQuickInspect: () => {},
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      twitterIcon: twitterIcon,
      iconAfterTitle: twitterIcon,
    },
    Twitter__EngagementsPage__dialects_and_sub_dialects: {
      typeWidget: "pieChart",
      title: getNameKeyWidget(
        "Twitter__EngagementsPage__dialects_and_sub_dialects",
      ),
      titleToolTip: "dialects_subdialects_ig_tooltip",
      data: dialects,
      subData: subDialects,
      areaStyle: true,
      toolTipLabel: "toolTipLabel",
      showAI: true,
      showDownloadIcon: true,
      pieChartPreLoaderDataSources: dialectsPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      twitterIcon: twitterIcon,
      iconAfterTitle: twitterIcon,
    },
    Twitter__EngagementsPage__top_countries: {
      typeWidget: "pieChart",
      title: getNameKeyWidget("Twitter__EngagementsPage__top_countries"),
      titleToolTip: "top_countries_tw_tooltip",
      data: topCountries,
      areaStyle: true,
      toolTipLabel: "toolTipLabel",
      showDownloadIcon: true,
      color: ["#009BDF", "#003D59", "#45B9E9", "#97D7F3"],
      pieChartPreLoaderDataSources: topCountriesPreLoader,
      // inspectorName: "countries",
      inspectorName: "",
      handleOpenQuickInspect: () => {},
      showAI: true,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      twitterIcon: twitterIcon,
      iconAfterTitle: twitterIcon,
    },
    Twitter__EngagementsPage__top_cities: {
      typeWidget: "pieChart",
      title: getNameKeyWidget("Twitter__EngagementsPage__top_cities"),
      titleToolTip: "top_cities_tw_tooltip",
      data: topCities,
      areaStyle: true,
      toolTipLabel: "toolTipLabel",
      showDownloadIcon: true,
      pieChartPreLoaderDataSources: topCitiesPreLoader,
      // inspectorName: "cities",
      inspectorName: "",
      handleOpenQuickInspect: () => {},
      showAI: true,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      twitterIcon: twitterIcon,
      iconAfterTitle: twitterIcon,
    },
    Twitter__EngagementsPage__gender_distribution: {
      typeWidget: "pieChart",
      title: getNameKeyWidget("Twitter__EngagementsPage__gender_distribution"),
      titleToolTip: "gender_distribution_tw_tooltip",
      data: genderDistribution,
      areaStyle: true,
      toolTipLabel: "toolTipLabel",
      showDownloadIcon: true,
      color: ["#2AAAE2", "#FF4081"],
      pieChartPreLoaderDataSources: genderDistributionPreLoader,
      showAI: true,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      twitterIcon: twitterIcon,
      iconAfterTitle: twitterIcon,
    },
    Twitter__EngagementsPage__account_types: {
      typeWidget: "pieChart",
      title: getNameKeyWidget("Twitter__EngagementsPage__account_types"),
      titleToolTip: "account_types_tw_tooltip",
      data: accountTypes,
      areaStyle: true,
      toolTipLabel: "toolTipLabel",
      showDownloadIcon: true,
      pieChartPreLoaderDataSources: accountTypesPreLoader,
      showAI: true,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      twitterIcon: twitterIcon,
      iconAfterTitle: twitterIcon,
    },
    Twitter__EngagementsPage__top_sources: {
      typeWidget: "pieChart",
      title: getNameKeyWidget("Twitter__EngagementsPage__top_sources"),
      titleToolTip: "top_sources_tw_tooltip",
      data: topSources,
      areaStyle: true,
      toolTipLabel: "toolTipLabel",
      showDownloadIcon: true,
      color: ["#009BDF", "#003D59", "#45B9E9", "#97D7F3"],
      pieChartPreLoaderDataSources: topSourcesPreLoader,
      twitterIcon: twitterIcon,
      // inspectorName: "sources",
      inspectorName: "",
      handleOpenQuickInspect: () => {},
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      twitterIcon: twitterIcon,
      iconAfterTitle: twitterIcon,
    },
    Twitter__EngagementsPage__volume_overtime: {
      typeWidget: "lineChart",
      title: getNameKeyWidget("Twitter__EngagementsPage__volume_overtime"),
      titleToolTip: "posts_volume_public_ig_tooltip",
      totalLabel: "posts",
      totalValue: totalCommentsVolumeCount,
      data: commentsVolume,
      showGroupBy: true,
      toolTipLabel: "posts",
      labelColor: "#2AAAE2",
      showDownloadIcon: true,
      bigImg: true,
      lineChartPreLoaderDataSources: commentsVolumePreLoader,
      handleOpenQuickInspect: () => {},
      // inspectorName: "comments_overtime",
      inspectorName: "",
      haveData: true,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      twitterIcon: twitterIcon,
      iconAfterTitle: twitterIcon,
    },
    Twitter__EngagementsPage__comments_interactions: {
      typeWidget: "stackedBarChart",
      title: getNameKeyWidget(
        "Twitter__EngagementsPage__comments_interactions",
      ),
      titleToolTip: "posts_interactions_tw_tooltip",
      showDownloadIcon: true,
      bigImg: true,
      posts: commentsInteractions,
      colors: ["#2AAAE2", "#80868C", "#334155"],
      labels: ["likes", "comments", "retweets"],
      showGroupBy: false,
      showScales: true,
      showZoomPercentage: true,
      postsColumnPreLoaderDataSources: commentsInteractionsPreLoader,
      hideLegend: true,
      dataPiechart: commentsInteractionsLegend,
      showChartDetailsWidget: true,
      statsDetailsData: interactionsStats,
      isInteractionStatsWrapperStyle: true,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      twitterIcon: twitterIcon,
      iconAfterTitle: twitterIcon,
    },
    Twitter__EngagementsPage__sentiment_analysis: {
      typeWidget: "stackedLineChart",
      title: getNameKeyWidget("Twitter__EngagementsPage__sentiment_analysis"),
      titleToolTip: "posts_sentiment_analysis_tw_tooltip",
      data: sentimentAnalysis,
      showGroupBy: true,
      showScales: true,
      areaStyle: true,
      toolTipLabel: "toolTipLabel",
      showAI: true,
      showDownloadIcon: true,
      bigImg: true,
      handleOpenQuickInspect: () => {},
      // inspectorName: "sentiment_overtime",
      inspectorName: "",
      dataPiechart: sentimentPieChart,
      hideLegend: true,
      labelColors: ["#89BB2A", "#E50035", "#F9A700"],
      isSwitchCase: true,
      isSentimentAnalysisWidget: true,
      isSentimentAnalysisShowCharts: true,
      stackedLineWidgetPreLoaderDataSources: sentimentAnalysisPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      twitterIcon: twitterIcon,
      iconAfterTitle: twitterIcon,
    },
    Twitter__EngagementsPage__reach_funnel: {
      typeWidget: "stackedBarChart",
      title: getNameKeyWidget("Twitter__EngagementsPage__reach_funnel"),
      titleToolTip: "reach_funnel_tw_tooltip",
      showDownloadIcon: true,
      bigImg: true,
      posts: reachFunnel,
      colors: ["#64748B", "#2AAAE2"],
      labels: ["Retweets", "original"],
      showScales: true,
      reachFunnel: true,
      postsColumnPreLoaderDataSources: reachFunnelPreLoaderDataSources,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      twitterIcon: twitterIcon,
      iconAfterTitle: twitterIcon,
    },
    Twitter__EngagementsPage__top_posts_comments: {
      typeWidget: "questionCard",
      questionCardTitle: getNameKeyWidget(
        "Twitter__EngagementsPage__top_posts_comments",
      ),
      questionCardIcon: twitterIcon,
      unixDate: unixDate,
      // showProfile: true,
      productId: window.localStorage?.getItem("sm_id"),
      latestUnanswer: topPosts?.slice(0, 6),
      icon: xPlatformWhite,
      iconColor: "twitter_icon",
      showBanIcon: true,
      latestUnanswerPreloader: topTopPostsPreLoaderDataSources,
      updateAnalysis: updateAnalysis,
      noOfChar: 15,
      singleTweetAnalysis: singleTweetAnalysis,
      hideWhenRetweeted: true,
      showRetweet: true,
      showHeart: true,
      showScreenName: true,
      showDefaultImg: true,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
    },
    Twitter__EngagementsPage__top_keywords: {
      typeWidget: "tableWidget",
      wordsList: true,
      title: getNameKeyWidget("Twitter__EngagementsPage__top_keywords"),
      titleToolTip: "posts_top_keywords_tw_tooltip",
      data: topKeywords,
      leftColomnTitle: "keywords",
      rightColomnTitle: "number_of_posts",
      tableChartPreLoaderDataSources: topKeywordsPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      keywords: true,
      tablePagination: true,
      count: topKeywords?.length,
      iconAfterTitle: twitterIcon,
      handleOpenQuickInspect: () => {},
      // inspectorName: "top_keywords",
      inspectorName: "",
      dynamicClass: "rowid-name-quick-inspect",
    },
    Twitter__EngagementsPage__top_hashtags: {
      typeWidget: "tableWidget",
      wordsList: true,
      title: getNameKeyWidget("Twitter__EngagementsPage__top_hashtags"),
      titleToolTip: "posts_top_hashtags_tw_tooltip",
      data: topHashtags,
      leftColomnTitle: "hashtags",
      rightColomnTitle: "number_of_posts",
      tableChartPreLoaderDataSources: topHashtagsPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      hashtags: true,
      tablePagination: true,
      count: topHashtags?.length,
      iconAfterTitle: twitterIcon,
      // inspectorName: "top_hashtags",
      inspectorName: "",
      handleOpenQuickInspect: () => {},
      dynamicClass: "rowid-name-quick-inspect",
    },
    Twitter__EngagementsPage__top_images: {
      typeWidget: "mediaTw",
      title: CheckValueLocale(
        getNameKeyWidget("Twitter__EngagementsPage__top_images"),
        "",
        {},
        intl,
      ),
      titleToolTip: "posts_top_images_tooltip",
      data: topImages,
      errorMessage: CheckValueLocale("no_images_found", "", {}, intl),
      iconAfterTitle: twitterIcon,
      mediaTwPreLoaderDataSources: topImagesPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
    },
    Twitter__EngagementsPage__top_videos: {
      typeWidget: "mediaTw",
      title: CheckValueLocale(
        getNameKeyWidget("Twitter__EngagementsPage__top_videos"),
        "",
        {},
        intl,
      ),
      titleToolTip: "posts_top_videos_tooltip",
      data: topVideos,
      errorMessage: CheckValueLocale("no_videos_found", "", {}, intl),
      iconAfterTitle: twitterIcon,
      mediaTwPreLoaderDataSources: topVideosPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
    },
    Twitter__EngagementsPage__top_verified_engagers: {
      typeWidget: "tableWidget",
      unixDate: unixDate,
      // showProfile: true,
      productId: window.localStorage?.getItem("sm_id"),
      monitorId: monitorId,
      circularPP: true,
      itemsList: true,
      title: getNameKeyWidget(
        "Twitter__EngagementsPage__top_verified_engagers",
      ),
      titleToolTip: "top_verified_engagers_tw_tooltip_twitter",
      isXPlatform: true,
      secondaryTitle: "engagement_overview_title",
      data: topVerifiedEngagers,
      leftColomnTitle: "users",
      rightColomnTitle: "followers",
      tableChartPreLoaderDataSources: topVerifiedEngagersPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      twitterIcon: twitterIcon,
      tablePagination: true,
      count: topVerifiedEngagers?.length,
      iconAfterTitle: twitterIcon,
    },
    Twitter__EngagementsPage__top_engagers: {
      typeWidget: "tableWidget",
      unixDate: unixDate,
      // showProfile: true,
      productId: window.localStorage?.getItem("sm_id"),
      monitorId: monitorId,
      circularPP: true,
      itemsList: true,
      title: getNameKeyWidget("Twitter__EngagementsPage__top_engagers"),
      titleToolTip: "top_engagers_tw_tooltip_twitter",
      data: topEngagers,
      leftColomnTitle: "users",
      rightColomnTitle: "followers",
      isXPlatform: true,
      secondaryTitle: "engagement_overview_title",
      tableChartPreLoaderDataSources: topEngagersPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      twitterIcon: twitterIcon,
      tablePagination: true,
      count: topEngagers?.length,
      iconAfterTitle: twitterIcon,
    },
    Twitter__EngagementsPage__top_influencers: {
      typeWidget: "tableWidget",
      unixDate: unixDate,
      // showProfile: true,
      productId: window.localStorage?.getItem("sm_id"),
      monitorId: monitorId,
      circularPP: true,
      itemsList: true,
      title: getNameKeyWidget("Twitter__EngagementsPage__top_influencers"),
      titleToolTip: "top_infleuncers_tw_tooltip_twitter",
      isXPlatform: true,
      secondaryTitle: "engagement_overview_title",
      data: topInfleuncers,
      leftColomnTitle: "user",
      rightColomnTitle: "followers",
      tableChartPreLoaderDataSources: topInfleuncersPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      twitterIcon: twitterIcon,
      tablePagination: true,
      count: topInfleuncers?.length,
      iconAfterTitle: twitterIcon,
    },
    Twitter__EngagementsPage__themes_and_sub_themes: {
      typeWidget: "themeSection",
      iconAfterTitle: twitterIcon,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      eventName: "Twitter__EngagementsPage__themes_and_sub_themes",
      resetAllFields: resetAllFields,
      response: response,
      themeTrendPreLoader: themeTrendPreLoader,
      setThemeTrendPreLoader: setThemeTrendPreLoader,
      themeAnalysisPreLoader: themeAnalysisPreLoader,
      setThemeAnalysisPreLoader: setThemeAnalysisPreLoader,
    },
    // posts section
    Twitter__PostsPage__top_posts_comments: {
      typeWidget: "questionCard",
      questionCardTitle: getNameKeyWidget(
        "Twitter__PostsPage__top_posts_comments",
      ),
      questionCardIcon: twitterIcon,
      unixDate: unixDate,
      // showProfile: true,
      productId: window.localStorage?.getItem("sm_id"),
      latestUnanswer: cardData,
      latestUnanswerPreloader: postsPreloader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      icon: xPlatformWhite,
      iconColor: "twitter_icon",
      showRetweet: true,
      showBanIcon: true,
      showScreenName: true,
      updateAnalysis: updateAnalysis,
      singleTweetAnalysis: singleTweetAnalysis,
      noOfChar: 15,
      hideWhenRetweeted: true,
      showHeart: true,
      showDefaultImg: true,
    },
    // questions section
    Twitter__QuestionsPage__latest_unanswered_questions: {
      typeWidget: "questionCard",
      questionCardTitle: getNameKeyWidget(
        "Twitter__QuestionsPage__latest_unanswered_questions",
      ),
      questionCardIcon: twitterIcon,
      unixDate: unixDate,
      // showProfile: true,
      productId: window.localStorage?.getItem("sm_id"),
      latestUnanswer: latestUnansweredQuestions,
      // unAnsweredQuestion: totalPage,
      // ShowPagination: true,
      // changePagination: changePagination,
      latestUnanswerPreloader: latestUnansweredQuestionsPreloader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      icon: xPlatformWhite,
      iconColor: "twitter_icon",
      showRetweet: true,
      showBanIcon: true,
      showScreenName: true,
      updateAnalysis: updateAnalysis,
      noOfChar: 15,
      singleTweetAnalysis: singleTweetAnalysis,
      showHeart: true,
      showDefaultImg: true,
    },
    // comments mentions section
    Twitter__CommentsMentionsPage__top_posts_comments: {
      typeWidget: "questionCard",
      questionCardTitle: getNameKeyWidget(
        "Twitter__CommentsMentionsPage__top_posts_comments",
      ),
      questionCardIcon: twitterIcon,
      unixDate: unixDate,
      // showProfile: true,
      productId: window.localStorage?.getItem("sm_id"),
      latestUnanswer: commentsMentions,
      // unAnsweredQuestion: totalPage,
      // commentsHeader: true,
      // ShowPagination: true,
      // changePagination: changePagination,
      latestUnanswerPreloader: commentsMentionsPreloader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      icon: xPlatformWhite,
      iconColor: "twitter_icon",
      showRetweet: true,
      showBanIcon: true,
      showScreenName: true,
      updateAnalysis: updateAnalysis,
      showHeart: true,
      noOfChar: 15,
      singleTweetAnalysis: singleTweetAnalysis,
      hideWhenRetweeted: true,
      showDefaultImg: true,
    },
    // customer care section
    Twitter__CustomerCarePage__customer_care_performance: {
      typeWidget: "barChart",
      title: getNameKeyWidget(
        "Twitter__CustomerCarePage__customer_care_performance",
      ),
      titleToolTip: "customer_performance_ig_tooltip",
      data: customerCareData,
      showAI: true,
      showDownloadIcon: true,
      showChartStatsWidget: true,
      showZoomPercentage: true,
      customerCareChart: true,
      gridHeight: "70%",
      respTimeDistributionTooltip: "respone_time_distribution_tooltip",
      statsData: [
        {
          name: "total_ques",
          value:
            customerCarePerformance?.total_number_of_questions &&
            commasAfterDigit(
              customerCarePerformance?.total_number_of_questions,
            ),
          icon: faQuestion,
          titleToolTip: "number_of_ques_tooltip",
        },
        {
          name: "number_of_answered_questions",
          value: isNaN(respRate * 100)
            ? 0 + "%"
            : respRate.toFixed(2) * 100 + "%",
          icon: faPercent,
          titleToolTip: "response_rate_tooltip",
        },
        {
          name: "avg_response_time_in_seconds",
          value: customerCarePerformance?.avg_response_time_in_seconds,
          icon: faClock,
          type: "time",
          titleToolTip: "avg_response_time_tooltip",
        },
      ],
      dataColor: "#2AAAE2",
      toolTipLabel: "posts",
      isTwitterAccount: true,
      iconAfterTitle: twitterIcon,
      barChartPreLoaderDataSources: customerCarePreloader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
    },
    Twitter__CustomerCarePage__latest_unanswered_questions: {
      typeWidget: "questionCard",
      questionCardTitle: getNameKeyWidget(
        "Twitter__CustomerCarePage__latest_unanswered_questions",
      ),
      questionCardIcon: twitterIcon,
      unixDate: unixDate,
      // showProfile: true,
      productId: window.localStorage?.getItem("sm_id"),
      latestUnanswer: latestUnanswer,
      latestUnanswerPreloader: latestUnanswerPreloader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      icon: xPlatformWhite,
      iconColor: "twitter_icon",
      showRetweet: true,
      showBanIcon: true,
      showScreenName: true,
      className: "no-data-found-question",
      updateAnalysis: updateAnalysis,
      noOfChar: 15,
      singleTweetAnalysis: singleTweetAnalysis,
      showHeart: true,
      showDefaultImg: true,
    },
    // account section
    Twitter__AccountPage__followers_growth: {
      typeWidget: "barChart",
      title: getNameKeyWidget("Twitter__AccountPage__followers_growth"),
      titleToolTip: "followers_growth_tw_tooltip",
      data: followersGrowth,
      showGroupBy: true,
      dataColor: "#2AAAE2",
      showChartDetailsWidget: true,
      showZoomPercentage: true,
      statsDetailsData: [
        {
          name: "total_followers",
          value: totalFollowersFollowersGrowth,
        },
        {
          name: "max_change_of_followers",
          value: maxChangeFollowersGrowth,
        },
        {
          name: "avg_change_per_day",
          value: avgChangeFollowersGrowth,
        },
      ],
      toolTipLabel: "posts",
      showFollowersGrowthStats: true,
      showDownloadIcon: true,
      bigImg: true,
      iconAfterTitle: twitterIcon,
      barChartPreLoaderDataSources: followersGrowthPreLoaderDataSources,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
    },
    Twitter__AccountPage__account_author_activity: {
      typeWidget: "punchCard",
      title: getNameKeyWidget("Twitter__AccountPage__account_author_activity"),
      titleToolTip: "account_authors_activity_tw_tooltip",
      clientActivity: authorActivity,
      minValueClient: minValueAuthorActivity,
      maxValueClient: maxValueAuthorActivity,
      showDownloadIcon: true,
      bigImg: true,
      iconAfterTitle: twitterIcon,
      authorActivityPreLoaderDataSources: authorActivityPreLoaderDataSources,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
    },
    Twitter__AccountPage__reach_funnel: {
      typeWidget: "stackedBarChart",
      title: getNameKeyWidget("Twitter__AccountPage__reach_funnel"),
      titleToolTip: "reach_funnel_tw_tooltip",
      showDownloadIcon: true,
      bigImg: true,
      showScales: true,
      posts: reachFunnelAccount,
      colors: ["#64748B", "#2AAAE2"],
      labels: ["Retweets", "original"],
      reachFunnelAccount: true,
      iconAfterTitle: twitterIcon,
      postsColumnPreLoaderDataSources: reachFunnelAccountPreLoaderDataSources,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      reachFunnel: true,
    },
    Twitter__AccountPage__volume_overtime: {
      typeWidget: "lineChart",
      title: getNameKeyWidget("Twitter__AccountPage__volume_overtime"),
      titleToolTip: "posts_volume_public_ig_tooltip",
      totalLabel: "posts",
      totalValue: totalPostsVolumeCount,
      data: postsVolume,
      showGroupBy: true,
      toolTipLabel: "posts",
      labelColor: "#2AAAE2",
      showDownloadIcon: true,
      bigImg: true,
      iconAfterTitle: twitterIcon,
      lineChartPreLoaderDataSources: postsVolumePreLoaderDataSources,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
    },
    Twitter__AccountPage__posts_interactions: {
      typeWidget: "stackedBarChart",
      title: getNameKeyWidget("Twitter__AccountPage__posts_interactions"),
      titleToolTip: "posts_interactions_tw_tooltip",
      showDownloadIcon: true,
      bigImg: true,
      posts: postsInteractions,
      showChartDetailsWidget: true,
      showScales: true,
      showGroupBy: false,
      statsDetailsData: [
        {
          name: "max_interaction",
          value: maxValuePostsInteractionsAccount,
          date: maxValueInteractionsDateAccount,
        },
        {
          name: "min_interaction",
          value: minValuePostsInteractions,
          date: minValueInteractionsDate,
        },
        {
          name: "avg_interaction_per_day",
          value: avgValuePostsInteractionsAccount,
        },
      ],
      colors: ["#2AAAE2", "#80868C", "#334155"],
      labels: ["Retweets", "likes", "replies"],
      showPostsInteractionStats: true,
      showZoomPercentage: true,
      iconAfterTitle: twitterIcon,
      postsColumnPreLoaderDataSources: postsInteractionsPreLoaderDataSources,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      hideLegend: true,
      dataPiechart: postInteractionsLegend,
    },
    Twitter__AccountPage__content_type: {
      typeWidget: "stackedBarChart",
      title: getNameKeyWidget("Twitter__AccountPage__content_type"),
      titleToolTip: "posts_content_type_tw_tooltip",
      posts: postsContentType,
      colors: ["#2AAAE2", "#80868C", "#334155", "#AFBCCE"],
      labels: ["video", "image", "url", "text"],
      showDownloadIcon: true,
      showScales: true,
      bigImg: true,
      showGroupBy: false,
      showZoomPercentage: true,
      iconAfterTitle: twitterIcon,
      postsColumnPreLoaderDataSources: postsContentTypePreLoaderDataSources,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      hideLegend: true,
      dataPiechart: contentTypeLegend,
    },
    Twitter__AccountPage__top_videos: {
      typeWidget: "mediaTw",
      title: CheckValueLocale(
        getNameKeyWidget("Twitter__AccountPage__top_videos"),
        "",
        {},
        intl,
      ),
      titleToolTip: "top_videos_tw_tooltip",
      data: topVideosAccount,
      errorMessage: CheckValueLocale("no_videos_found", "", {}, intl),
      iconAfterTitle: twitterIcon,
      mediaTwPreLoaderDataSources: topVideosAccountPreLoaderDataSources,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
    },
    Twitter__AccountPage__top_images: {
      typeWidget: "mediaTw",
      title: CheckValueLocale(
        getNameKeyWidget("Twitter__AccountPage__top_images"),
        "",
        {},
        intl,
      ),
      titleToolTip: "top_images_tw_tooltip",
      data: topImagesAccount,
      errorMessage: CheckValueLocale("no_images_found", "", {}, intl),
      iconAfterTitle: twitterIcon,
      mediaTwPreLoaderDataSources: topImagesAccountPreLoaderDataSources,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
    },
  };

  const handleResponseFunc = async () => {
    if (response?.eventName === "Twitter__EngagementsPage__associated_topics") {
      setAssociatedTopics(response?.eventData?.associated_topics);
      setAssociatedTopicsPreLoader((oldArray) => [
        ...oldArray,
        response?.monitor_id,
      ]);
    } else if (
      response?.eventName === "Twitter__EngagementsPage__content_style"
    ) {
      setContentStyles(response?.eventData);
      setContentStylesPreLoader((oldArray) => [
        ...oldArray,
        response?.monitor_id,
      ]);
    } else if (
      response?.eventName === "Twitter__EngagementsPage__top_languages"
    ) {
      setTopLanguages(
        translateLocalsFromRabbitMQ(
          "Twitter__EngagementsPage__top_languages",
          response?.eventData?.top_languages,
        ),
      );
      setDataToSend({
        ...dataToSend,
        languages: _.cloneDeep(response?.eventData?.top_languages),
      });
      setTopLanguagesPreLoader((oldArray) => [
        ...oldArray,
        response?.monitor_id,
      ]);
      // ___________________________________________________________
    } else if (
      response?.eventName ===
      "Twitter__EngagementsPage__dialects_and_sub_dialects"
    ) {
      setDialects(response?.eventData?.dialects);
      setSubDialects(response?.eventData?.sub_dialects);
      setDialectsPreLoader((oldArray) => [...oldArray, response?.monitor_id]);
      // ___________________________________________________________
    } else if (response?.eventName === "Twitter__EngagementsPage__top_cities") {
      setTopCities(response?.eventData?.top_cities);
      setDataToSend({
        ...dataToSend,
        cities: _.cloneDeep(response?.eventData?.top_cities),
      });
      setTopCitiesPreLoader((oldArray) => [...oldArray, response?.monitor_id]);
      // ___________________________________________________________
    } else if (
      response?.eventName === "Twitter__EngagementsPage__top_countries"
    ) {
      setTopCountries(response?.eventData?.top_countries);
      setDataToSend({
        ...dataToSend,
        countries: _.cloneDeep(response?.eventData?.top_countries),
      });
      setTopCountriesPreLoader((oldArray) => [
        ...oldArray,
        response?.monitor_id,
      ]);
      // ___________________________________________________________
    } else if (
      response?.eventName === "Twitter__EngagementsPage__gender_distribution"
    ) {
      setGenderDistribution(response?.eventData?.top_genders);
      setGenderDistributionPreLoader((oldArray) => [
        ...oldArray,
        response?.monitor_id,
      ]);
      // ___________________________________________________________
    } else if (
      response?.eventName === "Twitter__EngagementsPage__account_types"
    ) {
      setAccountTypes(response?.eventData?.account_types);
      setAccountTypesPreLoader((oldArray) => [
        ...oldArray,
        response?.monitor_id,
      ]);

      // ___________________________________________________________
    } else if (
      response?.eventName === "Twitter__EngagementsPage__top_sources"
    ) {
      setTopSources(response?.eventData?.top_sources);
      setDataToSend({
        ...dataToSend,
        sources: _.cloneDeep(response?.eventData?.top_sources),
      });
      setTopSourcesPreLoader((oldArray) => [...oldArray, response?.monitor_id]);
      return null;

      // ___________________________________________________________
      // lineChartWidgetContent
    } else if (
      response?.eventName === "Twitter__EngagementsPage__volume_overtime"
    ) {
      setCommentsVolume(response?.eventData?.posts_over_time);
      setTotalCommentsVolumeCount(response?.eventData?.total_posts_count);
      setCommentsVolumePreLoader((oldArray) => [
        ...oldArray,
        response?.monitor_id,
      ]);
      // stackedChartWidgetContent
    } else if (
      response?.eventName === "Twitter__EngagementsPage__comments_interactions"
    ) {
      setCommentsInteractions(response?.eventData?.comments_interactions);
      setCommentsInteractionsLegend(
        response?.eventData?.comments_interactions_legends,
      );

      let totalInteractions = response?.eventData?.total_interactions
        ? response?.eventData?.total_interactions
        : 0;

      setTotalValuePostsInteractions(totalInteractions);
      setMaxValuePostsInteractions(response?.eventData?.max_interactions);
      let date = handleUnixFormat(response?.eventData?.max_interaction_date);
      setMaxValueInteractionsDate(date);
      setAvgValuePostsInteractions(response?.eventData?.average_interactions);

      setCommentsInteractionsPreLoader((oldArray) => [
        ...oldArray,
        response?.monitor_id,
      ]);
    } else if (
      response?.eventName === "Twitter__EngagementsPage__sentiment_analysis"
    ) {
      Object.entries(response?.eventData?.sentiment_overtime)?.forEach(
        ([key, value]) => {
          switch (key) {
            case "negative":
              value.map((i) => {
                return negativeArray.push(i);
              });
              break;
            case "positive":
              value.map((i) => {
                return positiveArray.push(i);
              });
              break;
            case "neutral":
              value.map((i) => {
                return neutralArray.push(i);
              });
              break;
            default:
              break;
          }
        },
      );
      setSentimentAnalysis({
        negative: negativeArray,
        positive: positiveArray,
        neutral: neutralArray,
      });
      setSentimentPieChart(response?.eventData?.sentiment_piechart);
      setSentimentAnalysisPreLoader((oldArray) => [
        ...oldArray,
        response?.monitor_id,
      ]);
    } else if (
      response?.eventName === "Twitter__EngagementsPage__reach_funnel"
    ) {
      setReachFunnel(response?.eventData?.reach_funnel);
      setReachFunnelPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response?.monitor_id,
      ]);
    } else if (
      response?.eventName === "Twitter__EngagementsPage__top_posts_comments"
    ) {
      setTopPosts(response?.eventData?.top_posts);
      setTopPostsCount(response?.eventData?.top_posts_count);
      setTopPostsPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response?.monitor_id,
      ]);
    } else if (response?.eventName === "Twitter__EngagementsPage__top_videos") {
      setTopVideos(response?.eventData?.top_videos);
      setTopVideosPreLoader((oldArray) => [...oldArray, response?.monitor_id]);
    } else if (response?.eventName === "Twitter__EngagementsPage__top_images") {
      setTopImages(response?.eventData?.top_images);
      setTopImagesPreLoader((oldArray) => [...oldArray, response?.monitor_id]);
    } else if (
      response?.eventName === "Twitter__EngagementsPage__top_keywords"
    ) {
      setTopKeywords(response?.eventData?.top_keywords);
      setTopKeywordsPreLoader((oldArray) => [
        ...oldArray,
        response?.monitor_id,
      ]);
    } else if (
      response?.eventName === "Twitter__EngagementsPage__top_hashtags"
    ) {
      setTopHashtags(response?.eventData?.top_hashtags);
      setTopHashtagsPreLoader((oldArray) => [
        ...oldArray,
        response?.monitor_id,
      ]);
    } else if (
      response?.eventName === "Twitter__EngagementsPage__top_verified_engagers"
    ) {
      setTopVerifiedEngagers(response?.eventData?.top_verified_engagers);
      setTopVerifiedEngagersPreLoader((oldArray) => [
        ...oldArray,
        response?.monitor_id,
      ]); // ___________________________________________________________
    } else if (
      response?.eventName === "Twitter__EngagementsPage__top_engagers"
    ) {
      setTopEngagers(response?.eventData?.top_engagers);
      setTopEngagersPreLoader((oldArray) => [
        ...oldArray,
        response?.monitor_id,
      ]);
      // ___________________________________________________________
    } else if (
      response?.eventName === "Twitter__EngagementsPage__top_influencers"
    ) {
      setTopInfleuncers(response?.eventData?.top_influencers);
      setTopInfleuncersPreLoader((oldArray) => [
        ...oldArray,
        response?.monitor_id,
      ]);
      // ___________________________________________________________
    } else if (
      response?.eventName === "Twitter__EngagementsPage__dashboard_data"
    ) {
      setTotalStatisticWidgets((prev) => ({
        totalPosts: prev?.totalPosts + response?.eventData?.total_posts,
        totalAuthors: prev?.totalAuthors + response?.eventData?.total_authors,
        totalInteractions:
          prev?.totalInteractions + response?.eventData?.total_interactions,
      }));
      setLoadingStatisticWidgets(false);
      // posts section
    } else if (
      response.eventName === "Twitter__PostsPage__top_posts_comments"
    ) {
      setCardData(response?.eventData?.top_posts);
      if (!postsPreloader.includes(parseInt(response?.monitor_id))) {
        setPostsPreloader([...postsPreloader, parseInt(response.monitor_id)]);
      }
      // questions section
    } else if (
      response.eventName ===
      "Twitter__QuestionsPage__latest_unanswered_questions"
    ) {
      setLatestUnansweredQuestions(
        response.eventData.latest_unanswered_questions,
      );
      setLatestUnansweredQuestionsPreloader((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
      // comments mentions section
    } else if (
      response.eventName === "Twitter__CommentsMentionsPage__top_posts_comments"
    ) {
      setCommentsMentions(response.eventData.top_posts);
      setCommentsMentionsPreloader((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
      // customer care section
    } else if (
      response.eventName ===
      "Twitter__CustomerCarePage__customer_care_performance"
    ) {
      setCustomercarePerformance(response.eventData);
      setCustomercarePreloader([
        ...customerCarePreloader,
        parseInt(response.monitor_id),
      ]);
      if (response?.eventData?.response_time_segment) {
        Object.keys(response.eventData?.response_time_segment).map(
          (val, index) => {
            setcustomerCareData((oldArray) => [
              ...oldArray,
              {
                name: val,
                value: Object.values(response.eventData?.response_time_segment)[
                  index
                ],
              },
            ]);
          },
        );
      }
      setRespRate(
        response.eventData.number_of_answered_questions /
          response.eventData.total_number_of_questions,
      );
      setTotalQuestion(response.eventData.total_number_of_questions);
    } else if (
      response.eventName ===
      "Twitter__CustomerCarePage__latest_unanswered_questions"
    ) {
      setLatestUnanswer(response.eventData.latest_unanswered_questions);
      setUnanswerQuestion(response.eventData.number_of_unanswered_questions);
      setLatestUnanswerPreloader([
        ...latestUnanswerPreloader,
        parseInt(response.monitor_id),
      ]);
      // account section
    }
    if (response.eventName === "Twitter__AccountPage__volume_overtime") {
      setPostsVolume(response.eventData.posts_over_time);
      setTotalPostsVolumeCount(response.eventData.total_posts_count);
      setPostsVolumePreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (
      response.eventName === "Twitter__AccountPage__account_author_activity"
    ) {
      setMinValueAuthorActivity(response.eventData.min_value);
      setMaxValueAuthorActivity(response.eventData.max_value);
      setAuthorActivity(response.eventData.account_author_activity);
      setAuthorActivityPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (
      response.eventName === "Twitter__AccountPage__followers_growth"
    ) {
      setFollowersGrowth(response.eventData.followers_change_over_time);
      setTotalFollowersGrowth(response.eventData.total_followers);
      setMaxChangeFollowersGrowth(response.eventData.max_change_of_followers);
      setAvgChangeFollowersGrowth(response.eventData.avg_chage_of_day);
      setFollowersGrowthPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (
      response.eventName === "Twitter__AccountPage__posts_interactions"
    ) {
      setPostsInteractions(
        response.eventData.posts_interactions.posts_interactions_over_time,
      );
      setMaxValuePostsInteractionsAccount(
        response.eventData.posts_interactions.max_interactions,
      );
      setMinValuePostsInteractions(
        response.eventData.posts_interactions.min_interactions,
      );
      setAvgValuePostsInteractionsAccount(
        response.eventData.posts_interactions.average_interactions,
      );
      let date = handleUnixFormat(
        response.eventData.posts_interactions.max_interaction_date,
      );
      setMaxValueInteractionsDateAccount(date);
      date = handleUnixFormat(
        response.eventData.posts_interactions.min_interaction_date,
      );
      setMinValueInteractionsDate(date);
      setPostInteractionsLegend(
        response.eventData.posts_interactions.posts_interactions_legends,
      );
      setPostsInteractionsPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (response.eventName === "Twitter__AccountPage__content_type") {
      setPostsContentType(response.eventData.posts_content_type);
      setContentTypeLegend(response.eventData.posts_content_types_legends);
      setPostsContentTypePreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (response.eventName === "Twitter__AccountPage__reach_funnel") {
      setReachFunnelAccount(response.eventData.reach_funnel);
      setReachFunnelAccountPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (response.eventName === "Twitter__AccountPage__top_videos") {
      setTopVideosAccount(response.eventData.top_videos);
      setTopVideosAccountPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (response.eventName === "Twitter__AccountPage__top_images") {
      setTopImagesAccount(response.eventData.top_images);
      setTopImagesAccountPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else {
      return null;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (resetAllFields !== 1) {
      setContentStyles([]);
      setContentStylesPreLoader([]);
      setAssociatedTopics([]);
      setAssociatedTopicsPreLoader([]);
      setTopLanguages([]);
      setTopLanguagesPreLoader([]);
      setDialects([]);
      setSubDialects([]);
      setDialectsPreLoader([]);
      setTopCities([]);
      setTopCitiesPreLoader([]);
      setTopCountries([]);
      setTopCountriesPreLoader([]);
      setGenderDistribution([]);
      setGenderDistributionPreLoader([]);
      setAccountTypes([]);
      setAccountTypesPreLoader([]);
      setTopSources([]);
      setTopSourcesPreLoader([]);

      // lineChartWidgetContent
      setCommentsVolume([]);
      setTotalCommentsVolumeCount([]);
      setCommentsVolumePreLoader([]);

      // stackedChartWidgetContent
      setCommentsInteractions([]);
      setCommentsInteractionsPreLoader([]);
      setCommentsInteractionsLegend([]);
      setTotalValuePostsInteractions(0);
      setMaxValuePostsInteractions(0);
      setMaxValueInteractionsDate(0);
      setAvgValuePostsInteractions(0);
      setReachFunnel([]);
      setReachFunnelPreLoaderDataSources([]);
      setSentimentAnalysis([]);
      setSentimentAnalysisPreLoader([]);
      setSentimentPieChart([]);
      setNegativeArray([]);
      setPositiveArray([]);
      setNeutralArray([]);
      setTopPosts([]);
      setTopPostsCount(0);
      setTopPostsPreLoaderDataSources([]);
      setTopVideos([]);
      setTopVideosPreLoader([]);
      setTopImages([]);
      setTopImagesPreLoader([]);
      setTopKeywords([]);
      setTopKeywordsPreLoader([]);
      setTopHashtags([]);
      setTopHashtagsPreLoader([]);
      setTopVerifiedEngagers([]);
      setTopVerifiedEngagersPreLoader([]);
      setTopEngagers([]);
      setTopEngagersPreLoader([]);
      setTopInfleuncers([]);
      setTopInfleuncersPreLoader([]);

      // posts section
      setPostsPreloader([]);
      setCardData([]);

      // questions section
      setLatestUnansweredQuestions([]);
      setLatestUnansweredQuestionsPreloader([]);

      // comments mentions section
      setCommentsMentions([]);
      setCommentsMentionsPreloader([]);

      // customer care section
      setLatestUnanswer([]);
      setCustomercarePreloader({});
      setLatestUnanswerPreloader([]);
      setCustomercarePreloader([]);
      setUnanswerQuestion(0);
      setcustomerCareData([]);

      // account section
      setPostsVolume([]);
      setTotalPostsVolumeCount([]);
      setTopImagesAccount([]);
      setTopVideosAccount([]);
      setTopPostsAccount([]);
      setTopPostsAccountCount(0);
      setMinValueAuthorActivity(0);
      setMaxValueAuthorActivity(0);
      setPostsInteractions([]);
      setMaxValuePostsInteractionsAccount(0);
      setMinValuePostsInteractions(0);
      setAvgValuePostsInteractionsAccount(0);
      setMaxValueInteractionsDateAccount(0);
      setMinValueInteractionsDate(0);
      setPostInteractionsLegend([]);
      setPostsContentType([]);
      setContentTypeLegend([]);
      setFollowersGrowth([]);
      setTotalFollowersGrowth(0);
      setMaxChangeFollowersGrowth(0);
      setAvgChangeFollowersGrowth(0);
      setReachFunnelAccount([]);
      setPostsVolumePreLoaderDataSources([]);
      setTopVideosAccountPreLoaderDataSources([]);
      setTopImagesAccountPreLoaderDataSources([]);
      setTopPostsAccountPreLoaderDataSources([]);
      setAuthorActivityPreLoaderDataSources([]);
      setPostsInteractionsPreLoaderDataSources([]);
      setPostsContentTypePreLoaderDataSources([]);
      setFollowersGrowthPreLoaderDataSources([]);
      setReachFunnelAccountPreLoaderDataSources([]);
    }
  }, [resetAllFields]);

  //this function is used for open the quick-inspect-model
  // const handleOpenQuickInspect = (value, name, sentimentValue) => {
  //   switch (true) {
  //     case name === "comments_overtime":
  //       setDataValue(
  //         props.engagementsKeyword ? "posts_mentions" : "comments_mentions",
  //       );
  //       setInspectorValue(props.engagementsKeyword ? "posts" : "comments");
  //       setColor("#2AAAE2");
  //       setDate(value.split("-").reverse().join("/"));
  //       break;
  //     case name === "sentiment_overtime":
  //       setDataValue(sentimentValue);
  //       setInspectorValue(
  //         CheckValueLocale(sentimentValue, "", {}, intl) +
  //           " " +
  //           CheckValueLocale("volume", "", {}, intl),
  //       );
  //       sentimentValue === "positive"
  //         ? setColor("#BAD532")
  //         : sentimentValue === "negative"
  //           ? setColor("#ED1846")
  //           : setColor("#F5B819");
  //       setDate(value.split("-").reverse().join("/"));

  //       break;
  //     //incase of new design for quick inspect from sentiment legend
  //     case name === "sentiment_legend":
  //       setDataValue(
  //         sentimentValue + " " + CheckValueLocale(value, "", {}, intl),
  //       );
  //       setInspectorValue(CheckValueLocale(value + "_volume", "", {}, intl));
  //       value === "positive"
  //         ? setColor("#8CC63F")
  //         : value === "negative"
  //           ? setColor("#ED1846")
  //           : setColor("#F5B819");

  //       setDate(
  //         CheckValueLocale("all_time_sentiment", "", {}, intl) +
  //           dateFormRefactor(reduxFilterStartDateUX) +
  //           " - " +
  //           dateFormRefactor(reduxFilterEndDateUX),
  //       );
  //       break;
  //     case name === "top_hashtags" || name === "top_keywords":
  //       setDataValue(value);
  //       setInspectorValue(value);
  //       setColor("#2AAAE2");
  //       setDate(
  //         dateFormRefactor(reduxFilterStartDateUX) +
  //           "-" +
  //           dateFormRefactor(reduxFilterEndDateUX),
  //       );
  //       break;
  //     default:
  //       setDataValue(sentimentValue + "% " + value);
  //       setInspectorValue(value);
  //       setColor("#2AAAE2");
  //       setDate(
  //         dateFormRefactor(reduxFilterStartDateUX) +
  //           "-" +
  //           dateFormRefactor(reduxFilterEndDateUX),
  //       );
  //   }
  //   setQuickResponse([]);
  //   setInspectorName(name);
  //   setOpen(true);

  //   let valueToSend;
  //   if (dataToSend[name] !== undefined && dataToSend[name].length !== 0) {
  //     valueToSend = dataToSend[name].filter(
  //       (data) =>
  //         CheckValueLocale(data.name, "", {}, intl).toUpperCase() ===
  //         value.toUpperCase(),
  //     );
  //   }
  //   value =
  //     valueToSend && valueToSend.length !== 0 ? valueToSend[0].name : value;
  //   name === "sentiment_overtime"
  //     ? getQuickInspectData(
  //         reduxFilterStartDateUX,
  //         reduxFilterEndDateUX,
  //         name,
  //         value,
  //         sentimentValue,
  //       )
  //     : getQuickInspectData(
  //         reduxFilterStartDateUX,
  //         reduxFilterEndDateUX,
  //         name,
  //         value,
  //       );
  // };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    sortWidgets(
      oldIndex,
      newIndex,
      xPlateformWidgetTypes,
      setXPlateformWidgetTypes,
    );
  };
  return (
    <>
      <Container
        axis={"xy"}
        onSortEnd={onSortEnd}
        useDragHandle
        useWindowAsScrollContainer
      >
        {xPlateformWidgetTypes
          ?.filter((item) => xPlateformWidgetObj[item?.name])
          ?.map((item, index) => (
            <Element
              key={index}
              index={index}
              widgetItem={xPlateformWidgetObj[item?.name]}
              showEditBtn={showEditBtn}
              widgetSection={item?.section}
            />
          ))}
      </Container>
    </>
  );
};
export default DataSourceContent;
