//This Page is the Main landing page when user chose a certain monitor ,
//it Includes Right Side Bar + Monitor Body + Monitors Common Header

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import MonitorsController from "services/controllers/monitorsController";
import CompaniesController from "services/controllers/companiesController";
import LiveDashboardController from "services/controllers/liveDashboardController";
import { connectWithRabbitMQ } from "services/controllers/rabbitMQResponce";
import { convertXPlateformToTwitter, isEmptyObj } from "utils/helpers";
import MonitorSideBarAndHeader from "./Components/monitorHeader/monitorSideBarAndHeader";
import InstagramEngagementsPage from "./publicAccount/instagramAccountAnalysis/engagements/engagementPage";
import AccountPage from "./publicAccount/twitterAccountAnalysis/account/accountPage";
import AuthorsPage from "./publicAccount/twitterAccountAnalysis/authors/authorsPage";
import CommentsMentionsPage from "./publicAccount/twitterAccountAnalysis/commentsMentions/commentsMentionsPage";
import CustomerCarePage from "./publicAccount/twitterAccountAnalysis/customerCare/customerCarePage";
import EngagementsPage from "./publicAccount/twitterAccountAnalysis/engagements/engagementPage";
import PostsPage from "./publicAccount/twitterAccountAnalysis/posts/postsPage";
import QuestionsPage from "./publicAccount/twitterAccountAnalysis/questions/questionsPage";
import CircularLoading from "components/circularProgress";
import PublicFBAccountPage from "./publicAccount/facebookAccountAnalysis/account/accountPage";
import PublicFBEngagementPage from "./publicAccount/facebookAccountAnalysis/engagements/engagementsPage";
/*_______________________________Twitter Keyword_______________________________*/
import KeywordsEngagementPage from "./keywordsAccount/twitterKeywords/engagements/engagementPage";
import KeywordsPostsPage from "./keywordsAccount/twitterKeywords/posts/postsPage";
import CustomerCareIG from "./managedAccount/instagramManaged/customerCare/customerCarePage";

/*______________________________NewsBlogs Keyword________________________________*/
import KeywordsNewsBlogEngagementPage from "./keywordsAccount/newsBlogs/engagements/engagementPage";
import KeywordsNewsBlogPostPage from "./keywordsAccount/newsBlogs/posts/postsPage";
/*___________________________________________________________________________________*/
import InstagramAccountPage from "./publicAccount/instagramAccountAnalysis/account/accountPage";
import InstagramPostsPage from "./publicAccount/instagramAccountAnalysis/posts/postsPage";

/*_______________________________Instagram Keyword_____________________________*/
import KeywordInstagramEngagementsPage from "./keywordsAccount/instagramKeywords/engagements/engagementsPage";

/*_______________________________Facebook Keyword_____________________________*/
import KeywordFacebookPostsPage from "./keywordsAccount/facebookKeywords/posts/postsPage";
import KeywordFacebookEngagementPage from "./keywordsAccount/facebookKeywords/engagements/engagementPage";
import KeywordFacebookCommentsMentionsPage from "./keywordsAccount/facebookKeywords/commentsMentions/commentsMentionsPage";

// Managed Pages //
import ManagedAccountPage from "pages/Monitors/managedAccount/instagramManaged/account/accountPage";
import InstagramEngagement from "./managedAccount/instagramManaged/engagements/engagementsPage";
import AuthorsProfilePage from "./publicAccount/twitterAccountAnalysis/authors/authorProfile/authorProfilePage";
import DeepInsightsPage from "./publicAccount/twitterAccountAnalysis/authors/deepInsights/deepInsightsPage";
import InstagramManagedPostsPage from "./managedAccount/instagramManaged/posts/postsPage";
import IGCommentsMentionsPage from "./managedAccount/instagramManaged/commentsMentions/commentsMentionsPage";
import IGQuestionsPage from "./managedAccount/instagramManaged/questions/questionsPage";

/*______________________________________________________________*/
import ManagedFBAccountPage from "./managedAccount/facebookManaged/account/accountPage";
import ManagedFBEngagementPage from "./managedAccount/facebookManaged/engagements/engagementPage";
import ManagedFBPostsPage from "./managedAccount/facebookManaged/posts/postsPage";
import ManagedFBCustomerCarePage from "./managedAccount/facebookManaged/customerCare/customerCarePage";
import ManagedFBQuestionPage from "./managedAccount/facebookManaged/questions/questionsPage";
import SnackBar from "components/snackBar";
import PopupModal from "components/popupModal";
import { useIntl } from "react-intl";
import SavedFiltersFunctions from "../../shared/reduxComponents/filters/general/savedFiltersFunctions.js";
import CommonFunctions from "./Components/commonFunctions";
import { CheckValueLocale } from "utils/helpers";
/*______________________________________________________________*/
import LuciFunctions from "pages/luci/components/luciFunctions/luciFunction";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { reduxApplicableFilters } from "utils/redux/features/SocialListening/socialListeningSlice";
import { getActiveParams } from "shared/general-filter/activeFilters/activeFilters";
import {
  filters,
  filtersCount,
  reduxShowUserSnackbar,
} from "utils/redux/features/SocialListening/socialListeningSlice";
const MainMonitorPage = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  /*______________________________________________________________*/
  //get All used monitor details from url link
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const showMoreUser = searchParams.get("user_name");
  const urlParams = useParams();

  useEffect(() => {
    if (showMoreUser) {
      dispatch(
        filters({
          users: showMoreUser,
        }),
      );
      dispatch(filtersCount(1));
    }
  }, []);

  const monitorType = urlParams.monitorType;

  const dataSourceName = convertXPlateformToTwitter(
    urlParams.monitorDataSource,
  );
  const monitorDataSource =
    dataSourceName === "TALKWALKER" || dataSourceName === "NEWSBLOGS"
      ? "nb"
      : dataSourceName;
  const activeTab = urlParams.activeTab;
  const monitorId = urlParams.monitorId;
  const isAuthorProfile =
    useHistory().location.pathname.includes("author-profile"); //if avaliable redirect user to AuthorsProfilePage.js
  const isDeepInsights =
    useHistory().location.pathname.includes("deep-insights"); //if avaliable redirect user to DeepInsightsPage.js
  /*______________________________________________________________*/
  //  Start Redux Declarations

  const reduxFilterParams = useSelector(
    (state) => state?.socialListening?.newFilterParams,
  );

  const reduxFilterStartDateUX = useSelector(
    (state) => state?.socialListening?.startDateUnix,
  );
  const reduxFilterEndDateUX = useSelector(
    (state) => state?.socialListening?.endDateUnix,
  );
  const unixDate = {
    start: reduxFilterStartDateUX,
    end: reduxFilterEndDateUX,
  };
  //  End Redux Declarations

  let startDate = reduxFilterStartDateUX; //start Date is set to be last 7 days by default
  let endDate = reduxFilterEndDateUX;

  const [isApplicableFilterCalculated, setIsApplicableFilterCalculated] =
    useState(false);

  const [preLoaderTrackerDataSources, setPreLoaderTrackerDataSources] =
    useState([]);
  const [resetAllFields, setResetAllFields] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [singleTweetAnalysis, setSingleTweetAnalysis] = useState(false); //this param is passed to enable/disable 'analyze tweet btn' in twitter pages
  const [excelSheetData, setExcelSheetData] = useState([]);
  /*______________________________________________________________*/

  const mqttConnectionClosed = React.useRef(false);
  const clientMQTT = React.useRef();
  const [response, setResponse] = useState([]);
  const [showFilterBtn, setShowFilterBtn] = useState(true);
  /*_______________________________________________________________________________________*/
  const [checkLuciAllDataExist, setLuciCheckAllDataExist] = useState(false);
  const [checkLuciDataIsEmpty, setLuciCheckDataIsEmpty] = useState(false);
  /*_______________________________________________________________________________________*/
  //In Case of Copy/Paste any URL these Functions are going top be fired
  //to get Monitor Data taht are missing in history.state
  const history = useHistory();
  const [monitorData, setMonitorData] = useState(history.location.state);
  const [isTopicsEnabled, setIsTopicEnabled] = useState(false);
  const [customerCareFlag, setCustomerCareFlag] = useState();
  const [hiddenCustomerCareAlert, setHiddenCustomerCareAlert] = useState(false);
  const [topicsCount, setTopicsCount] = useState(0);
  const [monitorLiveDashboardDetails, setMonitorLiveDashboardDetails] =
    useState(null);
  const [monitorLiveDashboardListLoading, setMonitorLiveDashboardListLoading] =
    useState(true);
  let activeTabNames = {
    account: "account",
    posts: "posts",
    engagements: "engagements",
  };

  let monitorTypes = {
    ACCOUNT_ANALYSIS: "ACCOUNT_ANALYSIS",
    KEYWORD: "KEYWORD",
  };
  let monitorDataSources = {
    INSTAGRAM: "INSTAGRAM",
  };

  useEffect(() => {
    if (urlParams.monitorId) {
      getMonitorInfo();
      getMonitorLiveDashboardList();
    }
  }, [urlParams.monitorId]);

  const getMonitorInfo = () => {
    //to get monitor data that are not sent within location.state in case of copy past the URL
    MonitorsController.getMonitorInfo(
      urlParams.monitorId,
      window.localStorage.sm_id,
    ).then((data) => {
      setMonitorData(data.data);
    });
  };
  /*_______________________________________________________________________________________*/
  const [insightsID, setInsightsID] = useState([]);
  const [insightsResponse, setInsightsResponse] = useState([]);
  const [dates, setDates] = useState([]);

  const userId = localStorage.getItem("user_id");

  const getMonitorLiveDashboardList = () => {
    LiveDashboardController.getMonitorLiveDashboardList({
      monitor_id: urlParams.monitorId,
    }).then((data) => {
      setMonitorLiveDashboardDetails(
        data?.data?.monitor_user_live_dashboard?.find(
          (item) => +item?.user_id === +userId,
        ),
      );
      setMonitorLiveDashboardListLoading(false);
    });
  };

  useEffect(() => {
    setInsightsResponse(
      localStorage.getItem("moreInsightsResp") !== null
        ? JSON.parse(localStorage.getItem("moreInsightsResp"))
        : [],
    );
    setInsightsID(
      localStorage.getItem("moreInsightsIds") !== null
        ? JSON.parse(localStorage.getItem("moreInsightsIds"))
        : [],
    );
    setDates(
      localStorage.getItem("moreInsightsDates") !== null
        ? JSON.parse(localStorage.getItem("moreInsightsDates"))
        : [],
    );
  }, []);

  /*_____________________________________________________________________________________*/
  // useEffect to hide filter, add condition with OR, to hide___________________________
  useEffect(() => {
    if (
      (activeTab === "account" &&
        !(
          monitorDataSource === "TWITTER" && monitorType === "ACCOUNT_ANALYSIS"
        )) ||
      (activeTab === "posts" &&
        !(
          monitorType === "KEYWORD" ||
          (monitorDataSource === "TWITTER" &&
            monitorType === "ACCOUNT_ANALYSIS")
        )) ||
      (activeTab === "engagements" &&
        monitorType === "ACCOUNT_ANALYSIS" &&
        monitorDataSource === "INSTAGRAM")
    ) {
      //hidden in All Account tabs
      //hide filters in posts page in public: twitter, instagram, facebook || , managed: instagram and facebook
      setShowFilterBtn(false);
    }
  }, []);

  /*_____________________________________________________________________________________*/
  //This is the first Function and API call  Done when user open the monitor page
  const applyFilter = (
    startDate,
    endDate,
    activeFilterParams,
    pageNumber,
    isAppliedFilter,
    applicableFilterCalculated,
  ) => {
    if (isApplicableFilterCalculated || applicableFilterCalculated) {
      var selectedFilterParams = //this is to connect filter params with date change and vise versa
        activeFilterParams !== undefined
          ? activeFilterParams
          : !isAppliedFilter
            ? assignActiveFilters
            : filterParams;
      if (
        (activeTab === activeTabNames["account"] &&
          !(
            monitorDataSource === "TWITTER" &&
            monitorType === "ACCOUNT_ANALYSIS"
          )) ||
        (activeTab === "posts" &&
          !(
            monitorType === "KEYWORD" ||
            (monitorDataSource === "TWITTER" &&
              monitorType === "ACCOUNT_ANALYSIS")
          )) ||
        (activeTab === activeTabNames["engagements"] &&
          monitorType === monitorTypes["ACCOUNT_ANALYSIS"] &&
          monitorDataSource === monitorDataSources["INSTAGRAM"])
      ) {
        selectedFilterParams =
          selectedFilterParams?.sort_by !== undefined
            ? { sort_by: selectedFilterParams?.sort_by }
            : {};
      }

      setResetAllFields((prev) => prev + 1);
      var activePageNumber = pageNumber ? pageNumber : 1;
      setPageNumber(activePageNumber);

      getSocialMediaMonitorData(
        startDate,
        endDate,
        selectedFilterParams,
        activePageNumber,
      );
      setAssignActiveFilters(selectedFilterParams);
      if (activeFilterParams?.sort_by) {
        setPostsSortBy(activeFilterParams?.sort_by);
      }
    }
  };

  /*______________________________________________________________*/
  //This API Call return credentials for specific page and connect with rabbitMQ to get monitor data as response
  const {
    checkAllDataFounded,
    checkDataIsFounded,
    checkAllDataExist,
    checkDataIsEmpty,
    setCheckDataIsEmpty,
    changeFilterParamsToString,
    totalPostsCount,
    setTotalPostsCount,
    postsSortBy,
    setPostsSortBy,
    autoSummaryActive,
    getSummaryStaticQuestions,
    summaryStaticQuestions,
    summaryStaticQuestionsLoading,
  } = CommonFunctions();

  const getSocialMediaMonitorData = (
    reduxFilterStartDateUX,
    reduxFilterEndDateUX,
    reduxFilterParams,
    pageNumber,
  ) => {
    setResponse([]); //empty reponse wuth each new call
    let fillterObj = { ...reduxFilterParams };

    MonitorsController.getSocialMediaMonitorData(
      monitorDataSource, //sent to dynamically call the correct API based on datasource name
      monitorId,
      window.localStorage.sm_id,
      activeTab, //chosen active section/tab from side menu
      reduxFilterStartDateUX,
      reduxFilterEndDateUX,
      fillterObj &&
        //format FilterParams to be string instead of array and remove empty array params
        changeFilterParamsToString(fillterObj),
      pageNumber,
    ).then((data) => {
      setSingleTweetAnalysis(data?.data?.single_tweet_analysis_enabled); //this param is passed to enable/disable 'analyze tweet btn' in twitter pages
      if (!isEmptyObj(data?.data)) {
        setIsTopicEnabled(data?.data?.manual_topics_enabled);
        setCustomerCareFlag(data?.data?.customer_care_accounts);
        setTopicsCount(data?.data?.monitor_topics_used);
        setPreLoaderTrackerDataSources([parseInt(monitorId)]);
        connectWithRabbitMQ(
          mqttConnectionClosed,
          clientMQTT,
          data.data,
          setResponse,
        );
      } else {
        mqttConnectionClosed.current = true;
      }
    });
  };

  const { checkLuciDataIsFounded, checkLuciAllDataFounded } = LuciFunctions();

  /*______________________________________________________________*/
  //Handle Pagination with [ Posts Page , Comments & Mentions Page ]
  const changePagination = (pageNumber) => {
    setPageNumber(pageNumber);
    applyFilter(startDate, endDate, undefined, pageNumber); //sending filters as undefined is handled inside applyfilter fn
  };
  // Save And Apply Filter________________________________________________________________
  const {
    modalBody,
    filterName,
    handleDeleteFilter,
    getSavedFilterData,
    handleApplySaveFilter,
    handleShowSaveAndApplyModal,
    handleCloseSnackBar,
    showSaveAndApplyModal,
    showSnackBar,
    handleClearFilterParams,
    filterParams,
    setFilterParams,
    handleCloseApplySaveFilter,
    snackBarDetails,
    filterFields,
    getFiltersFileds,
    savedFilters,
    setSavedFilterId,
    savedFilterId,
    showDeleteFilterModal,
    handleCloseDeleteFilterModal,
    setShowDeleteFilterModal,
    setAssignActiveFilters,
    assignActiveFilters,
    setFilterName,
    dataSourceId,
  } = SavedFiltersFunctions(
    monitorId,
    activeTab,
    monitorDataSource,
    monitorType,
    startDate,
    endDate,
    applyFilter,
  );
  const childProps = {
    applyFilter,
    handleShowSaveAndApplyModal,
    startDate,
    endDate,
    filterFields,
    getFiltersFileds,
    assignActiveFilters, //showing active selected filters on top of the page
    allActiveFilters: getActiveParams(assignActiveFilters, filterFields), //showing active selected filters on top of the page
    isAuthorProfile, //to hide filter & filter by date in [ top engager page ]
    showFilterBtn,
    monitorData,
    savedFilters,
    getSavedFilterData,
    setShowDeleteFilterModal,
    handleClearFilterParams,
    filterParams,
    setFilterParams,
    setSavedFilterId,
    savedFilterId,
    setAssignActiveFilters,
    response,
    resetAllFields,
    preLoaderTrackerDataSources,
    singleTweetAnalysis,
    changePagination,
    pageNumber,
    monitorType,
    insightsID,
    insightsResponse,
    dates,
    activeTab,
    monitorDataSource,
    excelSheetData,
    setExcelSheetData,
    checkAllDataExist,
    checkDataIsEmpty,
    checkAllDataFounded,
    checkDataIsFounded,
    setCheckDataIsEmpty,
    isTopicsEnabled,
    customerCareFlag,
    setCustomerCareFlag,
    hiddenCustomerCareAlert,
    setHiddenCustomerCareAlert,
    topicsCount,
    setFilterName,
    filterName,
    dataSourceId,
    monitorId,
    setLuciCheckAllDataExist,
    checkLuciAllDataExist,
    setLuciCheckDataIsEmpty,
    checkLuciDataIsEmpty,
    checkLuciDataIsFounded,
    checkLuciAllDataFounded,
    totalPostsCount,
    setTotalPostsCount,
    postsSortBy,
    unixDate,
    getSummaryStaticQuestions,
    summaryStaticQuestions,
    summaryStaticQuestionsLoading,
    monitorLiveDashboardDetails,
    monitorLiveDashboardListLoading,
    dataSourceName,
  };
  useEffect(() => {
    if (filterFields?.length !== 0) {
      const applicableFilters = filterFields?.filter((allFilters) => {
        return Object.keys(reduxFilterParams).some((activeFiltersName) => {
          return allFilters.name === activeFiltersName;
        });
      });
      let applicableFiltersName = applicableFilters?.map((el) => {
        return el.name;
      });
      const finalObj = Object.fromEntries(
        Object.keys(reduxFilterParams)
          ?.filter((key) => applicableFiltersName.includes(key))
          ?.map((key) => [key, reduxFilterParams[key]]),
      );
      dispatch(reduxApplicableFilters(finalObj));
      setIsApplicableFilterCalculated(true);
      applyFilter(
        reduxFilterStartDateUX,
        reduxFilterEndDateUX,
        finalObj,
        undefined,
        undefined,
        true,
      );
    }
  }, [filterFields]);

  //handle Auto summary widget in monitor
  useEffect(() => {
    if (
      autoSummaryActive?.[monitorType]?.find(
        (active) => active === activeTab,
      ) &&
      dataSourceId
    ) {
      getSummaryStaticQuestions(dataSourceId);
    }
  }, [dataSourceId, monitorType, activeTab]);

  return (
    <>
      {monitorData === undefined ? ( //this happens in case of copy/past URL only
        <CircularLoading />
      ) : (
        <>
          <MonitorSideBarAndHeader {...childProps} isSLPage />
          {/*__________ redirection to each page based on url data (monitorType,monitorDataSource,activeTab)__________*/}
          {monitorType === "ACCOUNT_ANALYSIS" &&
          monitorDataSource === "TWITTER" &&
          activeTab === "account" ? (
            <AccountPage {...childProps} />
          ) : //________________________________________________________________________________________

          monitorType === "ACCOUNT_ANALYSIS" &&
            monitorDataSource === "TWITTER" &&
            activeTab === "engagements" ? (
            <EngagementsPage {...childProps} />
          ) : //________________________________________________________________________________________

          monitorType === "ACCOUNT_ANALYSIS" &&
            monitorDataSource === "TWITTER" &&
            activeTab === "customer_care" ? (
            <CustomerCarePage {...childProps} />
          ) : //________________________________________________________________________________________

          monitorType === "ACCOUNT_ANALYSIS" &&
            monitorDataSource === "TWITTER" &&
            activeTab === "posts" ? (
            <PostsPage {...childProps} />
          ) : //________________________________________________________________________________________

          (monitorType === "ACCOUNT_ANALYSIS" || monitorType === "KEYWORD") &&
            monitorDataSource === "TWITTER" &&
            activeTab === "authors" &&
            !isAuthorProfile &&
            !isDeepInsights ? (
            <AuthorsPage {...childProps} />
          ) : //________________________________________________________________________________________

          (monitorType === "ACCOUNT_ANALYSIS" || monitorType === "KEYWORD") &&
            monitorDataSource === "TWITTER" &&
            activeTab === "authors" &&
            isAuthorProfile ? (
            <AuthorsProfilePage {...childProps} />
          ) : //________________________________________________________________________________________

          (monitorType === "ACCOUNT_ANALYSIS" || monitorType === "KEYWORD") &&
            monitorDataSource === "TWITTER" &&
            activeTab === "authors" &&
            isDeepInsights ? (
            <DeepInsightsPage {...childProps} />
          ) : //________________________________________________________________________________________

          monitorType === "ACCOUNT_ANALYSIS" &&
            monitorDataSource === "TWITTER" &&
            activeTab === "comments_mentions" ? (
            <CommentsMentionsPage {...childProps} />
          ) : //________________________________________________________________________________________

          monitorType === "ACCOUNT_ANALYSIS" &&
            monitorDataSource === "TWITTER" &&
            activeTab === "questions" ? (
            <QuestionsPage {...childProps} />
          ) : //________________________________________________________________________________________

          monitorType === "ACCOUNT_ANALYSIS" &&
            monitorDataSource === "INSTAGRAM" &&
            activeTab === "engagements" ? (
            <InstagramEngagementsPage {...childProps} />
          ) : //________________________________________________________________________________________

          monitorType === "ACCOUNT_ANALYSIS" &&
            monitorDataSource === "INSTAGRAM" &&
            activeTab === "account" ? (
            <InstagramAccountPage {...childProps} />
          ) : //________________________________________________________________________________________

          monitorType === "ACCOUNT_ANALYSIS" &&
            monitorDataSource === "FACEBOOK" &&
            activeTab === "account" ? (
            <PublicFBAccountPage {...childProps} />
          ) : //________________________________________________________________________________________

          monitorType === "ACCOUNT_ANALYSIS" &&
            monitorDataSource === "FACEBOOK" &&
            activeTab === "engagements" ? (
            <PublicFBEngagementPage {...childProps} />
          ) : //________________________________________________________________________________________

          monitorType === "KEYWORD" &&
            monitorDataSource === "TWITTER" &&
            activeTab === "posts" ? (
            <KeywordsPostsPage {...childProps} />
          ) : //________________________________________________________________________________________

          monitorType === "KEYWORD" &&
            monitorDataSource === "TWITTER" &&
            activeTab === "engagements" ? (
            <KeywordsEngagementPage {...childProps} />
          ) : //________________________________________________________________________________________

          monitorType === "KEYWORD" &&
            monitorDataSource === "INSTAGRAM" &&
            activeTab === "engagements" ? (
            <KeywordInstagramEngagementsPage {...childProps} />
          ) : //________________________________________________________________________________________

          monitorType === "KEYWORD" &&
            monitorDataSource === "FACEBOOK" &&
            activeTab === "posts" ? (
            <KeywordFacebookPostsPage {...childProps} />
          ) : //________________________________________________________________________________________

          monitorType === "KEYWORD" &&
            monitorDataSource === "FACEBOOK" &&
            activeTab === "comments_mentions" ? (
            <KeywordFacebookCommentsMentionsPage {...childProps} />
          ) : //________________________________________________________________________________________

          monitorType === "KEYWORD" &&
            monitorDataSource === "FACEBOOK" &&
            activeTab === "engagements" ? (
            <KeywordFacebookEngagementPage {...childProps} />
          ) : //________________________________________________________________________________________

          monitorType === "MANAGED_ACCOUNT" &&
            monitorDataSource === "INSTAGRAM" &&
            activeTab === "account" ? (
            <ManagedAccountPage {...childProps} />
          ) : //________________________________________________________________________________________

          monitorType === "MANAGED_ACCOUNT" &&
            monitorDataSource === "INSTAGRAM" &&
            activeTab === "customer_care" ? (
            <CustomerCareIG {...childProps} />
          ) : //________________________________________________________________________________________

          monitorType === "MANAGED_ACCOUNT" &&
            monitorDataSource === "INSTAGRAM" &&
            activeTab === "engagements" ? (
            <InstagramEngagement {...childProps} />
          ) : //________________________________________________________________________________________

          (monitorType === "ACCOUNT_ANALYSIS" || monitorType === "KEYWORD") &&
            monitorDataSource === "INSTAGRAM" &&
            activeTab === "posts" ? (
            <InstagramPostsPage {...childProps} />
          ) : //________________________________________________________________________________________

          monitorType === "KEYWORD" &&
            monitorDataSource === "nb" &&
            activeTab === "engagements" ? (
            <KeywordsNewsBlogEngagementPage {...childProps} />
          ) : //________________________________________________________________________________________

          monitorType === "KEYWORD" &&
            monitorDataSource === "nb" &&
            activeTab === "posts" ? (
            <KeywordsNewsBlogPostPage {...childProps} />
          ) : //________________________________________________________________________________________

          monitorType === "MANAGED_ACCOUNT" &&
            monitorDataSource === "INSTAGRAM" &&
            activeTab === "posts" ? (
            <InstagramManagedPostsPage {...childProps} />
          ) : //________________________________________________________________________________________

          monitorType === "MANAGED_ACCOUNT" &&
            monitorDataSource === "INSTAGRAM" &&
            activeTab === "comments_mentions" ? (
            <IGCommentsMentionsPage {...childProps} />
          ) : //________________________________________________________________________________________

          monitorType === "MANAGED_ACCOUNT" &&
            monitorDataSource === "INSTAGRAM" &&
            activeTab === "questions" ? (
            <IGQuestionsPage {...childProps} />
          ) : //________________________________________________________________________________________

          monitorType === "MANAGED_PAGE" &&
            monitorDataSource === "FACEBOOK" &&
            activeTab === "account" ? (
            <ManagedFBAccountPage {...childProps} />
          ) : //________________________________________________________________________________________

          monitorType === "MANAGED_PAGE" &&
            monitorDataSource === "FACEBOOK" &&
            activeTab === "engagements" ? (
            <ManagedFBEngagementPage {...childProps} />
          ) : //________________________________________________________________________________________

          (monitorType === "MANAGED_PAGE" ||
              monitorType === "ACCOUNT_ANALYSIS") &&
            monitorDataSource === "FACEBOOK" &&
            activeTab === "posts" ? (
            <ManagedFBPostsPage {...childProps} />
          ) : //________________________________________________________________________________________

          (monitorType === "MANAGED_PAGE" ||
              monitorType === "ACCOUNT_ANALYSIS") &&
            monitorDataSource === "FACEBOOK" &&
            activeTab === "customer_care" ? (
            <ManagedFBCustomerCarePage {...childProps} />
          ) : //________________________________________________________________________________________

          (monitorType === "MANAGED_PAGE" ||
              monitorType === "ACCOUNT_ANALYSIS") &&
            monitorDataSource === "FACEBOOK" &&
            (activeTab === "comments_mentions" || activeTab === "questions") ? (
            <ManagedFBQuestionPage {...childProps} />
          ) : //________________________________________________________________________________________

          null}
        </>
      )}
      {showSaveAndApplyModal && (
        <PopupModal
          title={CheckValueLocale("save_filter_and_apply", "", {}, intl)}
          body={modalBody}
          leftBtn={CheckValueLocale("cancel", "", {}, intl)}
          rightBtn={CheckValueLocale("save_filter_and_apply", "", {}, intl)}
          open={showSaveAndApplyModal}
          close={handleCloseApplySaveFilter}
          accept={() => handleApplySaveFilter(savedFilterId)}
          addClasses="model-buttons"
          classeName="save-apply-modal"
          modalStyle="save-apply-modal-body"
        />
      )}
      {/* ________________________________________________________________________________________ */}

      {showDeleteFilterModal && (
        <PopupModal
          title={CheckValueLocale("delete_saved_filter", "", {}, intl)}
          body={CheckValueLocale(
            "delete_filter_confirmation",
            "",
            { filterName: filterName },
            intl,
          )}
          leftBtn={CheckValueLocale("cancel", "", {}, intl)}
          rightBtn={CheckValueLocale("delete_filter", "", {}, intl)}
          open={showDeleteFilterModal}
          close={handleCloseDeleteFilterModal}
          accept={handleDeleteFilter}
          addClasses="model-buttons"
          classeName="save-apply-modal"
          modalStyle="save-apply-modal-body"
          warning={true}
        />
      )}
      {/* ________________________________________________________________________________________ */}

      {showSnackBar && (
        <SnackBar
          open={showSnackBar}
          autoHideDuration={7000}
          handleClose={handleCloseSnackBar}
          severity={snackBarDetails.severity}
          message={snackBarDetails.message}
          title={snackBarDetails.title}
        />
      )}
      {/*__________End of redirection section__________*/}
    </>
  );
};

export default MainMonitorPage;
