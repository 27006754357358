import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { checkKeyboardLanguage, CheckValueLocale } from "utils/helpers";
import { Box, Card, CardContent, Input, Typography } from "@mui/material";
import "../../surveyBuilder.scss";

const NpsQuestion = (props) => {
  const {
    question,
    questionIndex,
    sectionIndex,
    handleQuestionTitle,
    handleQuestionDesc,
    sqIndex,
    ssIndex,
    handleCardClick,
    questionNum,
    isCDP,
    isAlert,
  } = props;

  const intl = useIntl();
  const [editable, setEditable] = useState(false);
  const [value, setValue] = useState({});
  const answers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

  let checkQuestion = isCDP ? question?.answer : question?.answer_list;

  const filteredAnswers = answers?.filter((answer) => answer === checkQuestion);

  useEffect(() => {
    if (question) {
      setEditable(question?.editable);
      setValue({ title: question?.text, description: question?.description });
    }
  }, [question]);
  return (
    <Card
      className={`cardStyle ${
        ssIndex === sectionIndex + 1 &&
        sqIndex === questionIndex + 1 &&
        "noError"
      }`}
      onClick={() => {
        if (!isCDP && !isAlert) {
          handleCardClick(sectionIndex, questionIndex);
        }
      }}
    >
      <CardContent
        className={`card-body title-question card-direction-container ${isCDP || isAlert ? "card-body-cdp" : ""}`}
      >
        <Box
          style={{ display: "flex" }}
          className={isCDP || isAlert ? "question-head-cdp" : ""}
        >
          <Box component="span" className={"questionNoStyle "}>
            {questionIndex ? questionIndex + 1 : questionNum}.
          </Box>
          <Input
            fullWidth
            multiline
            className="questionStylee"
            style={{
              direction: checkKeyboardLanguage(value?.title) ? "rtl" : "ltr",
            }}
            id={questionIndex}
            value={
              isCDP || isAlert
                ? question?.question
                : value?.title
                  ? CheckValueLocale(value?.title, "", {}, intl)
                  : ""
            }
            onChange={(e) => {
              handleQuestionTitle(
                e,
                sectionIndex,
                questionIndex,
                value,
                setValue,
              );
            }}
            name="title"
            placeholder={CheckValueLocale("name_question", "", {}, intl)}
            disableUnderline={true}
          />
        </Box>

        {!isAlert && !isCDP ? (
          <Input
            id={questionIndex}
            variant="outlined"
            className="subtitle"
            value={value?.description}
            onChange={(e) => {
              handleQuestionDesc(
                e,
                sectionIndex,
                questionIndex,
                value,
                setValue,
              );
            }}
            style={{
              direction: checkKeyboardLanguage(value?.description)
                ? "rtl"
                : "ltr",
            }}
            name="subtitle"
            placeholder={CheckValueLocale("question_description", "", {}, intl)}
            disableUnderline={true}
          />
        ) : null}

        <Box>
          <Box className="radio-item">
            {answers?.map((item, index) => {
              return (
                <label className="radio-item" key={index}>
                  <input
                    disabled
                    type="radio"
                    name={"colors" + index + questionIndex}
                    style={{
                      direction: checkKeyboardLanguage(value?.description)
                        ? "rtl"
                        : "ltr",
                    }}
                  />
                  <Box
                    component="span"
                    className={
                      isCDP || isAlert
                        ? index == filteredAnswers
                          ? "choose choose-cdp"
                          : "choose"
                        : question?.constraints?.colored_theme
                          ? index === 7 || index === 8
                            ? "choose yellow"
                            : index === 9 || index === 10
                              ? "choose green"
                              : "choose red"
                          : "choose"
                    }
                  >
                    <Box>{item}</Box>
                  </Box>
                </label>
              );
            })}
          </Box>
          <Box className="most-like">
            <Typography component="p">
              {CheckValueLocale("unlikely", "", {}, intl)}
            </Typography>
            <Typography component="p">
              {CheckValueLocale("very_likely", "", {}, intl)}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default NpsQuestion;
