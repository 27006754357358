import { Box, Typography } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
const UtilitiesSection = () => {
  const intl = useIntl();

  return (
    <Box className="utilities-section">
      <Box className="utilities-content">
        <Typography component={"h1"}>
          {CheckValueLocale("utilities_section_title", "", {}, intl)}
        </Typography>
        <Typography>
          {CheckValueLocale("utilities_section_desc", "", {}, intl)}
        </Typography>
      </Box>
      <a href="javascript:userpilot.trigger('P1-LyU4sJr')">
        {CheckValueLocale("explore_whats_new", "", {}, intl)}
      </a>
    </Box>
  );
};

export default UtilitiesSection;
