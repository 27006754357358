import Typography from "@mui/material/Typography";
import { Grid, OutlinedInput, Box } from "@mui/material";
import { useIntl } from "react-intl";
import "../../../../../../shared/createHeader/createHeader.scss";
import "pages/socialListing/MonitorsOptions/createMonitor/createMonitor.scss";
import { isPublicSpecialCharactersOrEmojis } from "../../../MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import { removeSpecialCharacters } from "../../../MonitorsShared/SharedFunctions/components/keywordLanguage.js";
import { CheckValueLocale } from "utils/helpers";

const MonitorInfoSections = (props) => {
  const intl = useIntl();
  const { monitorData, setMonitorData, setMonitorErrors, monitorErrors } =
    props;
  let inputFileds = ["monitor_name", "description_optional"];

  const handleInputChange = (e, input) => {
    let monitorInfo = e.target.value;
    if (
      isPublicSpecialCharactersOrEmojis(monitorInfo, undefined, true) ||
      removeSpecialCharacters(monitorInfo).length === 0
    ) {
      if (input === "monitor_name") {
        setMonitorErrors({
          ...monitorErrors,
          monitorName: "spam_keywords_rules",
        });
      } else {
        setMonitorErrors({
          ...monitorErrors,
          monitorDescription: "spam_keywords_rules",
        });
      }
    }

    if (input === "monitor_name") {
      if (
        !isPublicSpecialCharactersOrEmojis(monitorInfo, undefined, true) &&
        !(removeSpecialCharacters(monitorInfo).length === 0)
      ) {
        setMonitorErrors({ ...monitorErrors, monitorName: "" });

        if (monitorInfo.length > 100) {
          setMonitorErrors({
            ...monitorErrors,
            monitorName: "monitor_name_length_error",
          });
        }
      }
      setMonitorData({ ...monitorData, monitorName: e.target.value });
    } else {
      if (
        !isPublicSpecialCharactersOrEmojis(monitorInfo, undefined, true) &&
        !(removeSpecialCharacters(monitorInfo).length === 0)
      ) {
        setMonitorErrors({ ...monitorErrors, monitorDescription: "" });
      }
      setMonitorData({ ...monitorData, monitorDescription: e.target.value });
    }
  };

  return (
    <>
      <Typography variant="caption" className="select-monitor-style">
        {CheckValueLocale("add_monitor_informations", "", {}, intl)}:
      </Typography>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        className="social-media-fields"
      >
        {inputFileds.map((input, i) => (
          <Grid key={i} item xs={6} className="social-media-field-grid">
            <Typography variant="caption" className="optional-field">
              {CheckValueLocale(input, "", {}, intl)}
              {input === "monitor_name" ? (
                <Box component="span" className="required">
                  *
                </Box>
              ) : null}
            </Typography>

            <OutlinedInput
              variant="outlined"
              className="social-media-field"
              value={
                input === "monitor_name"
                  ? monitorData.monitorName
                  : monitorData.monitorDescription
              }
              placeholder={
                input === "description_optional"
                  ? CheckValueLocale("this_will_help_you", "", {}, intl)
                  : CheckValueLocale("type_the_monitor_name", "", {}, intl)
              }
              onChange={(e) => handleInputChange(e, input)}
              onBlur={(e) => {
                // clear description error because it is not mandatory to have a descrption
                if (e.target.value === "" && input === "description_optional") {
                  setMonitorErrors({
                    ...monitorErrors,
                    monitorDescription: "",
                  });
                }
              }}
            />
            {input === "monitor_name" && (
              <Box component="p" className="monitor-error-name">
                {CheckValueLocale(monitorErrors.monitorName, "", {}, intl)}
              </Box>
            )}

            {input === "description_optional" && (
              <Box component="p" className="monitor-error-name">
                {CheckValueLocale(
                  monitorErrors.monitorDescription,
                  "",
                  {},
                  intl,
                )}
              </Box>
            )}
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default MonitorInfoSections;
